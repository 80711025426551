// react && redux
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// styles
import paginationStyles from '../Layouts/pagination.module.scss';
import { customSelectMulti, customSelectNew } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';

// icons
import file from '../../assets/img/icons/AdjuntarRecto.svg';
import basura from '../../assets/img/icons/Basura.svg';
import closeIcon from '../../assets/img/icons/Cerrar.svg';
import downloadArrow from '../../assets/img/icons/downloadArrow.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

// externs
import Select from 'react-select';

// request
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';

//helpers && constant
import { genderList2 } from '../../helpers/constants';
import {
  convertBase64,
  loader,
  optionsSelect,
  removeDuplicates,
  validateEmptyString,
  validatePassword,
  valueSelect,
} from '../../helpers/helpers';

// components
import { Box } from '@mui/material';
import Pagination from 'react-js-pagination';
import { formatteDocument } from '../../helpers';
import GenericTable from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import CustomPopup from '../Popup/customPopup';
import SelectComponent from '../SelectComponent/SelectComponent';
import { Text } from '../UI/atoms';
import { customSwaltAlert } from './../../helpers/customSwaltAlert';

const TableUsers = () => {
  // states
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.user_data.id;
  const [data, setData] = useState({
    currentPage: 0,
    filters: {
      page: 1,
      perpage: 10,
      eaccount: idAccount,
      search: '',
      active: '',
    },
    modal: {
      action: '',
      show: false,
      title: '',
    },
    signature: {
      file: '',
      file64: '',
      fileUrl: '',
      fileName: '',
    },
    medical_record: '',
    email: '',
    doc_type: '',
    doc_num: '',
    first_name: '',
    first_surname: '',
    password_hash: '',
    birthday: '',
    second_name: '',
    second_surname: '',
    gender: '',
    superior: '',
    cellphone: '',
    perfiles: '',
    account_id: '',
    profile_list: [],
    profile_list_temp: [],
    tableModalData: [],
    authentication_code: '',
    trigger: 0,
  });

  const { currentPage, filters, trigger, modal } = data;

  const { results: listUsers, trigger: getListUsers, load: loadListUsers } = useGetMethod();

  const { results: listProfiles, trigger: getListProfiles, load: loadProfiles } = useGetMethod();

  const { results: documentList, trigger: getDocumentList } = useGetMethod();


  const {
    results: listSuperiors,
    trigger: getListSuperiors,
    load: loadListSuperiors,
  } = useGetMethod();

  const {
    results: listAccounts,
    trigger: getListAccounts,
    load: loadListAccounts,
  } = useGetMethod();

  const { results: listCandidate, trigger: getListCandidate } = useGetMethod();

  const { load: loadUpdateStateUser, trigger: updateStateUser } = usePostMethod();

  const { load: loadUpdateUser, trigger: updateUser } = usePostMethod();

  const { load: loadCreateUser, trigger: createUser } = usePostMethod();

  const documentOptions = documentList?.results?.map(service => ({
    tag: service.prefix,
    label: service.description,
    value: service.id,
  }));

  // table List
  const tableHeader = [
    <th key={1} className='px-2 text-start'>
      ID
    </th>,
    <th key={2} className='px-2 text-start'>
      Nombre Completo
    </th>,
    <th key={3} style={{ padding: '7px 1.6rem 7px 1.6rem' }}>
      Perfil
    </th>,
    <th key={4} className='px-2 text-start'>
      Correo
    </th>,
    <th key={5} className='px-2 text-center'>
      Estado
    </th>,
    <th key={6} className='px-2 text-center'></th>,
  ];

  const tableBody = dataList => {
    const body = [];
    if (Array.isArray(dataList)) {
      dataList?.forEach((el, i) => {
        body.push(
          <tr key={i}>
            <td className='px-2 text-start'>{formatteDocument(el?.doc_num).format ?? '-'}</td>
            <td className='px-2 text-start'>
              {el?.first_name} {el?.first_surname}
            </td>

            <td className='px-2 text-start'>
              {!el?.profile_list?.length ? (
                <ul className='my-2'>
                  <li>No relacionado</li>
                </ul>
              ) : (
                <ul className='my-2'>
                  {el?.profile_list?.map((item, index) => {
                    return (
                      <li key={index}>
                        {listAccounts?.results?.find(el => el?.id === item?.id_account)?.name} -{' '}
                        {item?.profile?.nombre}
                      </li>
                    );
                  })}
                </ul>
              )}
            </td>
            <td className='px-2 text-start'>{el?.email ?? '_'}</td>
            <td className='text-center'>
              <div className={el?.active ? tableStyles.greenState : tableStyles.redState}>
                {el?.active ? 'Habilitado' : 'Inhabilitado'}
              </div>
            </td>

            <td className='px-2 text-center'>
              <CustomPopup
                triggerSrc={threeDots}
                showEdit={true}
                editClickEvent={() => onModalEdit(el)}
                showEnable={true}
                isEnabled={el?.active}
                enableClickEvent={() => handleUpdateStateUser(el)}
              />
            </td>
          </tr>,
        );
      });
    }

    return body;
  };

  // table modal
  const tableHeaderModal = [
    <th key={1} className='px-2 text-start'>
      Cuenta
    </th>,
    <th key={2} style={{ padding: '7px 1.6rem 7px 1.6rem' }}>
      Perfiles
    </th>,
    <th key={2} className='px-2 text-start'>
      Candidato
    </th>,
    <th key={3} className='px-2 text-start'></th>,
  ];

  const tableBodyModal = dataList => {
    const body = [];
    if (Array.isArray(dataList)) {
      dataList?.forEach((el, i) => {
        body.push(
          <tr key={i}>
            <td className='px-2 text-start'>
              {el?.id_account} -{' '}
              {listAccounts?.results?.find(account => account?.id === el?.id_account)?.name}
            </td>

            <td className='px-2 text-start'>
              {el?.profile_list?.map(profile => (
                <ul key={profile?.key} className='my-2'>
                  <li>{profile?.label}</li>
                </ul>
              ))}
            </td>

            <td className='px-2 text-start'>{el?.candidate_name}</td>

            <td className='text-center'>
              <img
                src={basura}
                alt='delete profile'
                className={`${tableStyles.hoverFilterDarkBlue} pointer`}
                onClick={() => onDeleteListTableModal(el)}
              />
            </td>
          </tr>,
        );
      });
    }

    return body;
  };

  // functions
  const incrementTrigger = () => setData(state => ({ ...state, trigger: state.trigger + 1 }));

  const onChangeStateModal = ({ show = false, title = '', action = '' }) => {
    setData(state => ({
      ...state,
      modal: {
        show: show,
        title: title ? title : state?.modal?.title,
        action: action ? action : state.modal.action,
      },
    }));
  };

  const onChangeSetData = ({ key, key2 = false, value }) => {
    !key2
      ? setData(state => ({ ...state, [key]: value }))
      : setData(state => ({ ...state, [key]: { [key2]: value } }));
    if (key === 'account_id' && data.doc_num && value) {
      getListCandidate({
        url: '/payroll/candidate-list',
        objFilters: {
          eaccount: value,
          doc_number: parseInt(data?.doc_num),
          type_query: 2,
        },
        token: token,
      });
      setData(state => ({ ...state, candidate_id: '' }));
    }
  };

  const handleOnBlurId = () => {
    if (data?.account_id) {
      getListCandidate({
        url: '/payroll/candidate-list',
        objFilters: {
          eaccount: data?.account_id,
          doc_number: parseInt(data?.doc_num),
          type_query: 2,
        },
        token: token,
      });
    }
  };

  const onValidAddListTableModal = () => {
    if (!data?.account_id) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Seleccione una cuenta valida`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
      return false;
    }

    if (!data?.profile_list_temp?.length) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Seleccione un perfil valido`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
      return false;
    }

    return true;
  };

  const validateCellphone = () => {
    if (data?.cellphone?.length < 10) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo 'Teléfono' debe tener mínimo 10 números`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

      return false;
    }

    return true;
  };

  const validateCreateOrUpdateUser = () => {
    let validations = false;
    let validationsEdit = false;

    if (modal.action === 'post') {
      validations = validateEmptyString([
        {
          field: data?.doc_type,
          message: "El campo 'Tipo de ID' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.doc_num,
          message: "El campo 'Número' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.gender,
          message: "El campo 'Género' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.birthday,
          message: "El campo 'Fecha de nacimiento' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.first_name,
          message: "El campo 'Primer Nombre' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.first_surname,
          message: "El campo 'Primer Apellido' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.email,
          message: "El campo 'Correo' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.password_hash,
          message: "El campo 'Contraseña' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
      ]);

      if (!validations.valid) {
        return;
      }
    }

    if (modal.action === 'put') {
      validationsEdit = validateEmptyString([
        {
          field: data?.doc_type,
          message: "El campo 'Tipo de ID' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.doc_num,
          message: "El campo 'Número' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.gender,
          message: "El campo 'Género' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.birthday,
          message: "El campo 'Fecha de nacimiento' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.first_name,
          message: "El campo 'Primer Nombre' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.first_surname,
          message: "El campo 'Primer Apellido' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
        {
          field: data?.email,
          message: "El campo 'Correo' es obligatorio",
          icon: 'warning',
          title: 'Intenta de nuevo',
        },
      ]);
      if (!validationsEdit.valid) {
        return;
      }
    }

    const validations2 = data.tableModalData.length > 0;

    if (!validations2) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: data.account_id
          ? 'Debe asociar por lo menos un perfil'
          : 'Debe seleccionar una cuenta y por lo menos asociar un perfil',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    const validations3 = validateCellphone();

    if (validations2 && validations3) {
      if (data.modal.action === 'post') {
        if (validatePassword(data?.password_hash)) {
          handleCreateUser();
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, una minúscula, un número y un carácter especial.',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      } else {
        handleUpdateUser();
      }
    }
  };

  const addListTableModal = () => {
    if (onValidAddListTableModal()) {
      const isExistData = data?.tableModalData?.filter(el => el?.id_account === data?.account_id);
      const newFilter = data?.tableModalData?.filter(el => el?.id_account !== data?.account_id);

      if (isExistData?.length) {
        const listCompleteArray = [...data.profile_list_temp, ...isExistData[0].profile_list];

        const notDuplicates = removeDuplicates({
          arrayObject: listCompleteArray,
          keyComparative: 'value',
        });

        const tableModalData = [
          ...newFilter,
          {
            id_account: data?.account_id,
            candidate_id: data?.candidate_id,
            profile_list: [...notDuplicates.listClear],
            candidate_name: listCandidate?.results?.find(
              candidate => candidate.id_candidate === data?.candidate_id,
            )?.full_name,
          },
        ];

        setData(state => ({
          ...state,
          tableModalData,
        }));

        setData(state => ({
          ...state,
          account_id: '',
          profile_list_temp: [],
          candidate_id: '',
          candidate_name: '',
        }));
      } else {
        const dataFormat = [
          ...data.tableModalData,
          {
            id_account: data?.account_id,
            profile_list: data?.profile_list_temp,
            candidate_id: data?.candidate_id,
            candidate_name: listCandidate?.results?.find(
              candidate => candidate.id_candidate === data?.candidate_id,
            )?.full_name,
          },
        ];

        const addProfile_listPost = data?.profile_list_temp.map(el => ({
          id_account: data?.account_id,
          id_profile: el?.value,
          candidate_id: data?.candidate_id,
          candidate_name: listCandidate?.results?.find(
            candidate => candidate.id_candidate === data?.candidate_id,
          )?.full_name,
          profile: {
            id: el?.value,
          },
        }));

        setData(state => ({
          ...state,
          tableModalData: dataFormat,
          profile_list: [...state.profile_list, ...addProfile_listPost],
        }));

        setData(state => ({
          ...state,
          account_id: '',
          profile_list_temp: [],
          candidate_id: '',
          candidate_name: '',
        }));
      }
    }
  };

  const getFormatProfileListOnHttpPut = () => {
    const profileListPut = [];
    data?.tableModalData?.forEach(el => {
      el?.profile_list?.forEach(listItem => {
        profileListPut.push({
          id_account: el?.id_account,
          candidate_id: el?.candidate_id,
          id_profile: listItem?.value,
          profile: {
            id: listItem?.value,
          },
        });
      });
    });

    return profileListPut;
  };

  const onDeleteListTableModal = element => {
    const dataFormat = data?.tableModalData?.filter(el => el.id_account !== element?.id_account);
    const dataFormatProfile_list = data?.profile_list?.filter(
      el => el.id_account !== element?.id_account,
    );

    setData(state => ({
      ...state,
      tableModalData: dataFormat,
      profile_list: dataFormatProfile_list,
    }));
  };

  const clearState = () => {
    setData(state => ({
      ...state,
      currentPage,
      filters: {
        ...state.filters,
      },
      modal: {
        action: '',
        show: false,
        title: '',
      },
      signature: {
        file: '',
        file64: '',
        fileUrl: '',
        fileName: '',
      },
      medical_record: '',
      email: '',
      doc_type: '',
      doc_num: '',
      first_name: '',
      first_surname: '',
      password_hash: '',
      birthday: '',
      second_name: '',
      second_surname: '',
      gender: '',
      superior: '',
      cellphone: '',
      perfiles: '',
      account_id: '',
      profile_list: [],
      profile_list_temp: [],
      tableModalData: [],
      authentication_code: '',
      trigger: state.trigger,
    }));
  };

  const handleImagePick = async e => {
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    setData(state => ({
      ...state,
      signature: {
        ...state?.signature,
        fileName: e?.target?.files[0]?.name,
        fileUrl: URL?.createObjectURL(e?.target?.files[0]),
        file64,
      },
    }));
  };

  const onModalEdit = user => {
    const selectedUser = listUsers?.results?.find(el => el?.id === user?.id)?.profile_list;

    let dataFormat = [];

    selectedUser?.forEach(el => {
      listAccounts?.results?.forEach(account => {
        if (account?.id === el?.id_account) {
          if (dataFormat.length) {
            const isExist = dataFormat?.find(x => account?.id === x?.id_account) || {};

            if (Object.keys(isExist).length) {
              const indice = dataFormat.findIndex(a => a.id_account === account?.id);
              dataFormat[indice] = {
                ...dataFormat[indice],
                profile_list: [
                  ...dataFormat[indice].profile_list,
                  {
                    value: el?.id_profile,
                    label: listProfiles?.data?.find(profile => profile?.id === el?.id_profile)
                      ?.nombre,
                  },
                ],
              };
            } else {
              dataFormat.push({
                id_profile: el?.id_profile,
                id_account: el?.id_account,
                candidate_id: el?.id_candidate,
                candidate_name: el?.candidate_full_name,
                profile_list: [
                  {
                    value: el?.id_profile,
                    label: listProfiles?.data?.find(profile => profile?.id === el?.id_profile)
                      ?.nombre,
                  },
                ],
              });
            }
          } else {
            dataFormat.push({
              id_profile: el?.id_profile,
              id_account: el?.id_account,
              candidate_id: el?.id_candidate,
              candidate_name: el?.candidate_full_name,
              profile_list: [
                {
                  value: el?.id_profile,
                  label: listProfiles?.data?.find(profile => profile?.id === el?.id_profile)
                    ?.nombre,
                },
              ],
            });
          }
        }
      });
    });

    const addProfile_listPost = selectedUser?.map(el => ({
      id_account: el?.id_account,
      id_profile: el?.id_profile,
      candidate_name: el?.candidate_full_name,
      profile: {
        id: el?.id_profile,
      },
    }));

    setData(state => ({
      ...state,
      modal: {
        action: 'put',
        show: true,
        title: 'Editar usuario',
      },
      id: user?.id,
      email: user?.email,
      doc_type: user?.doc_type,
      doc_num: user?.doc_num,
      first_name: user?.first_name,
      first_surname: user?.first_surname,
      birthday: user?.birthday,
      second_name: user?.second_name,
      second_surname: user?.second_surname,
      gender: user?.gender,
      superior: user?.superior,
      signature: user?.signature,
      medical_record: user?.medical_record,
      cellphone: user?.cellphone,
      tableModalData: dataFormat,
      profile_list: addProfile_listPost,
      trigger: state.trigger,
      authentication_code: user?.authentication_code,
    }));
  };

  //actions
  const handleUpdateStateUser = user => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se ${user.active ? 'inhabilitará' : 'habilitará'} el usuario: ${user.first_name} ${user.first_surname}`,
      confirmButtonText: 'Aceptar',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        updateStateUser({
          url: '/users/activate/',
          token: token,
          noAlert: true,
          method: 'POST',
          body: {
            active: !user.active,
            email: user.email,
          },
          doAfterSuccess: () => {
            customSwaltAlert({
              icon: 'success',
              title: 'Actualizado exitosamente',
              text: `Se ha ${user.active ? 'Inhabilitado' : 'Habilitado'} el usuario: ${user.first_name} ${user.first_surname}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });

            incrementTrigger();
          },

          doAfterException: results => {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: results.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const handleUpdateUser = () => {
    const profile_list = getFormatProfileListOnHttpPut();

    updateUser({
      url: `/users/${data?.id}`,
      token: token,
      noAlert: true,
      method: 'PUT',
      body: {
        account_id: data?.account_id,
        birthday: data?.birthday,
        cellphone: data?.cellphone,
        doc_num: data?.doc_num,
        doc_type: data?.doc_type,
        email: data?.email,
        first_name: data?.first_name,
        first_surname: data?.first_surname,
        gender: data?.gender,
        medicalRecord: data?.medical_record,
        password_hash: data?.password_hash,
        second_name: data?.second_name,
        second_surname: data?.second_surname,
        superior: data?.superior,
        signature: data?.signature,
        profile_list: profile_list,
      },
      doAfterSuccess: results => {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha actualizado el usuario: ${data?.first_name} ${data?.first_surname}. ${results.authentication_code !== undefined ? 'Código de autenticación: ' + results.authentication_code : ''}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        incrementTrigger();
        clearState();
      },

      doAfterException: results => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const handleCreateUser = () => {
    const profile_list = getFormatProfileListOnHttpPut();

    createUser({
      url: '/users/',
      token: token,
      noAlert: true,
      method: 'POST',
      body: {
        account_id: data?.account_id,
        birthday: data?.birthday,
        cellphone: data?.cellphone,
        doc_num: data?.doc_num,
        doc_type: data?.doc_type,
        email: data?.email,
        first_name: data?.first_name,
        first_surname: data?.first_surname,
        gender: data?.gender,
        medicalRecord: data?.medical_record,
        password_hash: data?.password_hash,
        second_name: data?.second_name,
        second_surname: data?.second_surname,
        superior: data?.superior,
        signature: data?.signature,
        profile_list: profile_list,
      },
      doAfterSuccess: results => {
        customSwaltAlert({
          icon: 'success',
          title: 'Creado exitosamente',
          text: `Se ha creado el usuario: ${data?.first_name} ${data?.first_surname}. ${results.authentication_code !== undefined ? 'Código de autenticación: ' + results.authentication_code : ''}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        incrementTrigger();
        clearState();
      },

      doAfterException: results => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  useEffect(() => {
    getListUsers({
      url: `/users/params/`,
      objFilters: {
        ...filters,
        page: 1,
        perpage: 10,
      },
      token: token,
    });

    getListSuperiors({
      url: `/medical/generals/medicalUsers/`,
      token: token,
    });

    getListProfiles({
      url: `/profile/`,
      token: token,
    });

    getListAccounts({
      url: `/admin/accounts/`,
      objFilters: {
        active: 1,
      },
      token: token,
    });

    getDocumentList({
      url: `/payroll/document_types/`,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    getListUsers({
      url: `/users/params/`,
      objFilters: {
        page: 1,
        perpage: 10,
        eaccount: idAccount,
        search: '',
        active: '',
      },
      token: token,
    });
  }, [getListUsers, idAccount, token]);

  return (
    <>
      {(loadProfiles ||
        loadListUsers ||
        loadUpdateStateUser ||
        loadListAccounts ||
        loadListSuperiors ||
        loadUpdateUser ||
        loadCreateUser) &&
        loader}

      <div className={tableStyles.container} style={{ padding: '0 6rem' }}>
        <h1>Usuarios</h1>

        <div>
          <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
        </div>

        <div style={{ marginTop: '-5px', alignItems: 'center' }} className={tableStyles.mainRow}>
          <form
          // onSubmit={(e) => this.clickFilter(e)}
          >
            <div className='d-flex align-items-center gap-2'>
              <div>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  className={`${tableStyles.widthSelect} text-secondary w-100`}
                  onChange={e => {
                    setData(state => ({
                      ...state,
                      trigger: state.trigger + 1,
                      filters: { ...state.filters, active: e.value, page: 1 },
                    }));
                  }}
                  options={[
                    { value: '', label: 'Seleccionar...' },
                    { value: 0, label: 'Inhabilitado' },
                    { value: 1, label: 'Habilitado' },
                  ]}
                  placeholder='Seleccionar...'
                  styles={customSelectNew}
                  maxHeight={26}
                />
              </div>

              <input
                className={tableStyles.searchUsersAlt}
                style={{ height: '25px' }}
                type='text'
                placeholder='Buscar...'
                onChange={({ target }) => {
                  setData(state => ({
                    ...state,
                    filters: { ...state.filters, search: target.value },
                  }));
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setData(state => ({
                      ...state,
                      trigger: state.trigger + 1,
                      filters: { ...state.filters, page: 1 },
                    }));
                  }
                }}
              />
              <img
                src={Lupa}
                alt='filtrar'
                className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                style={{ width: '1.4rem' }}
                onClick={() => incrementTrigger()}
              />
            </div>
          </form>

          <div className={`${tableStyles.groupElems} align-items-center`}>
            <div
              onClick={() =>
                onChangeStateModal({ show: true, action: 'post', title: 'Crear usuario' })
              }
              style={{ height: '32px' }}
              className='d-flex justify-content-end groupAddButton align-items-center'
            >
              <label htmlFor='newAccident' className='darkGray fw-bold'>
                Crear usuario
              </label>
              <button
                className='addCheckButton mx-2'
                style={{
                  marginBottom: '1.5px',
                  marginTop: '3px',
                }}
              />
            </div>
          </div>
        </div>

        <ModalNew
          size='lg'
          title={modal?.title}
          show={modal.show}
          onHide={() => {
            onChangeStateModal({});
            clearState();
          }}
          btnYesDisabled={data?.profile_list_temp?.length}
          btnYesName='Guardar'
          btnYesEvent={() => validateCreateOrUpdateUser()}
          styleMainContent={{ height: '680px', overflow: 'auto' }}
        >
          <Box>
            <Box>
              <Box className={`${tableStyles.textClearBlue} fw-bold`} mb={1}>
                Información general
              </Box>

              <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'} gap={2} mb={1}>
                <div className='d-flex flex-column col'>
                  <label htmlFor='doc_type' className={tableStyles.labelFont}>
                    Tipo de ID <span className={`${tableStyles.ordOrangeText}`}>*</span>
                  </label>

                  <SelectComponent
                    options={documentOptions}
                    value={documentOptions?.find(x=>x.tag === data.doc_type)}
                    onChange={({ tag }) =>{
                      onChangeSetData({ key: 'doc_type', value: tag })}}
                  />
                </div>

                <div className='d-flex flex-column col'>
                  <label htmlFor='doc_num' className={tableStyles.labelFont}>
                    Número
                    <span className={`${tableStyles.ordOrangeText}`}>*</span>
                  </label>
                  <input
                    onBlur={handleOnBlurId}
                    disabled={data.modal.action === 'put'}
                    className='register-inputs'
                    name='doc_num'
                    type='text'
                    placeholder='Escribir...'
                    onChange={({ target }) =>
                      onChangeSetData({
                        key: 'doc_num',
                        value: formatteDocument(target?.value).value,
                      })
                    }
                    value={formatteDocument(data.doc_num).format}
                  />
                </div>

                <div className='d-flex flex-column col'>
                  <label htmlFor='gender' className={tableStyles.labelFont}>
                    Género
                    <span className={`${tableStyles.ordOrangeText}`}>*</span>
                  </label>

                  <SelectComponent
                    options={optionsSelect({
                      list: genderList2,
                      label: 'label',
                      value: 'value',
                    })}
                    value={valueSelect({
                      list: genderList2,
                      findId: 'value',
                      findLabel: 'label',
                      value: data?.gender,
                    })}
                    onChange={({ value }) => onChangeSetData({ key: 'gender', value: value })}
                  />
                </div>
              </Box>
            </Box>

            <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={2} mb={1}>
              <div className='d-flex flex-column col'>
                <label className={tableStyles.labelFont} htmlFor='birthday'>
                  Fecha de Nacimiento <span className={`${tableStyles.ordOrangeText}`}>*</span>
                </label>
                <input
                  className={`register-inputs ${tableStyles.crudModalInputDate}`}
                  type='date'
                  name='birthday'
                  onChange={({ target }) =>
                    onChangeSetData({ key: 'birthday', value: target?.value })
                  }
                  value={data?.birthday}
                />
              </div>

              <div className='d-flex flex-column col'>
                <label htmlFor='cellphone' className={tableStyles.labelFont}>
                  Teléfono <span className={`${tableStyles.ordOrangeText}`}>*</span>
                </label>

                <input
                  placeholder='Escribir...'
                  id='field3'
                  autoComplete='new-password'
                  className='register-inputs'
                  name='cellphone'
                  type='number'
                  onChange={({ target }) =>
                    onChangeSetData({ key: 'cellphone', value: target?.value })
                  }
                  value={data?.cellphone}
                />
              </div>
            </Box>

            <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr 1fr'} gap={2} mb={2}>
              <div className='d-flex flex-column col'>
                <label htmlFor='first_name' className={tableStyles.labelFont}>
                  Primer Nombre
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>
                </label>
                <input
                  placeholder='Escribir...'
                  className='register-inputs'
                  name='first_name'
                  type='text'
                  onChange={({ target }) =>
                    onChangeSetData({ key: 'first_name', value: target?.value })
                  }
                  value={data?.first_name}
                />
              </div>

              <div className='d-flex flex-column col'>
                <label htmlFor='second_name' className={tableStyles.labelFont}>
                  Segundo Nombre
                </label>
                <input
                  placeholder='Escribir...'
                  className='register-inputs'
                  name='second_name'
                  type='text'
                  onChange={({ target }) =>
                    onChangeSetData({ key: 'second_name', value: target?.value })
                  }
                  value={data?.second_name}
                />
              </div>

              <div className='d-flex flex-column col'>
                <label htmlFor='first_surname' className={tableStyles.labelFont}>
                  Primer Apellido
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>
                </label>
                <input
                  placeholder='Escribir...'
                  className='register-inputs'
                  name='first_surname'
                  type='text'
                  onChange={({ target }) =>
                    onChangeSetData({ key: 'first_surname', value: target?.value })
                  }
                  value={data?.first_surname}
                />
              </div>

              <div className='d-flex flex-column col'>
                <label htmlFor='second_surname' className={tableStyles.labelFont}>
                  Segundo Apellido
                </label>
                <input
                  placeholder='Escribir...'
                  className='register-inputs'
                  name='second_surname'
                  type='text'
                  onChange={({ target }) =>
                    onChangeSetData({ key: 'second_surname', value: target?.value })
                  }
                  value={data?.second_surname}
                />
              </div>
            </Box>

            <Box className={tableStyles.crudModalZonaCuenta2} mb={1}>
              <Box className={`${tableStyles.textClearBlue} fw-bold`} mb={1}>
                Cuenta
              </Box>

              <Box mb={1} gap={2} display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'}>
                <div className='d-flex flex-column col'>
                  <label htmlFor='email' className={tableStyles.labelFont}>
                    Correo
                    <span className={`${tableStyles.ordOrangeText}`}>*</span>
                  </label>
                  <input
                    placeholder='Escribir...'
                    id='field2'
                    autoComplete='new-password'
                    className='register-inputs'
                    name='email'
                    type='email'
                    onChange={({ target }) =>
                      onChangeSetData({ key: 'email', value: target?.value })
                    }
                    value={data?.email}
                  />
                </div>

                <div className='d-flex flex-column col'>
                  <label htmlFor='password_hash' className={tableStyles.labelFont}>
                    Contraseña
                    <span className={`${tableStyles.ordOrangeText}`}>*</span>
                  </label>
                  <input
                    id='field1'
                    autoComplete='new-password'
                    className='register-inputs'
                    name='password_hash'
                    placeholder='***'
                    type='password'
                    onChange={({ target }) =>
                      onChangeSetData({ key: 'password_hash', value: target?.value })
                    }
                    value={data?.password_hash}
                    disabled={data.modal.action === 'put'}
                  />
                </div>

                <div className='col'>
                  <div className='display-grid'>
                    <span htmlFor='first_name' className={tableStyles.labelFont}>
                      Firma
                    </span>

                    <Box display={'flex'} gap={1} alignItems='center'>
                      <label
                        htmlFor='imageUpload'
                        className={`${tableStyles.labelFileStyle} register-inputs pointer`}
                      >
                        <Box>
                          {modal?.action === 'post' && (
                            <Text
                              title={
                                data?.signature?.fileName ? data?.signature?.fileName : 'Cargar...'
                              }
                              color='#808080'
                            />
                          )}

                          {modal?.action === 'put' && (
                            <Text
                              title={
                                data?.signature?.length
                                  ? data?.signature?.split('/')[
                                      data?.signature?.split('/').length - 1
                                    ]
                                  : data?.signature?.fileName
                                    ? data?.signature?.fileName
                                    : 'Cargar...'
                              }
                              color='#808080'
                            />
                          )}
                        </Box>

                        <input
                          id='imageUpload'
                          type='file'
                          accept='image/png, image/jpeg'
                          className='d-none w-100'
                          placeholder='Cargar...'
                          onChange={e => handleImagePick(e)}
                        />

                        <img
                          src={file}
                          alt='agregar archivo'
                          className={tableStyles.iconFileStyle}
                        />
                      </label>

                      {typeof data?.signature === 'string' && data?.signature?.length ? (
                        <img
                          height={18}
                          src={closeIcon}
                          alt='remove signature'
                          className={'pointer'}
                          onClick={() => {
                            setData(state => ({
                              ...state,
                              signature: {
                                fileName: '',
                                fileUrl: '',
                                file64: '',
                                file: '',
                              },
                            }));
                          }}
                        />
                      ) : (
                        ''
                      )}

                      {data?.signature?.fileName ? (
                        <img
                          height={18}
                          src={closeIcon}
                          alt='remove signature'
                          className={'pointer'}
                          onClick={() => {
                            setData(state => ({
                              ...state,
                              signature: {
                                fileName: '',
                                fileUrl: '',
                                file64: '',
                                file: '',
                              },
                            }));
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  </div>
                </div>
              </Box>

              <Box
                gap={2}
                display={'grid'}
                gridTemplateColumns={
                  data?.authentication_code !== '' && data?.authentication_code !== null
                    ? '1fr 1fr 1fr'
                    : '1fr 1fr'
                }
              >
                <div className='d-flex flex-column col'>
                  <label htmlFor='superior' className={tableStyles.labelFont}>
                    Superior
                  </label>

                  <SelectComponent
                    options={listSuperiors?.results?.map(superior => ({
                      label: `${superior.user} - ${superior.jobTitle}`,
                      value: superior?.id,
                    }))}
                    value={valueSelect({
                      list: listSuperiors?.results,
                      findId: 'id',
                      findLabel: 'user',
                      value: data?.superior,
                    })}
                    onChange={({ value }) => onChangeSetData({ key: 'superior', value: value })}
                  />
                </div>
                {data?.authentication_code !== '' && data?.authentication_code !== null && (
                  <div className='d-flex flex-column '>
                    <label htmlFor='first_name' className={tableStyles.labelFont}>
                      Código de autenticación
                    </label>
                    <input
                      disabled
                      placeholder='Escribir...'
                      className='register-inputs'
                      name='medical_record'
                      type='text'
                      maxLength={50}
                      value={data?.authentication_code}
                    />
                  </div>
                )}
                <div className='d-flex flex-column col'>
                  <label htmlFor='first_name' className={tableStyles.labelFont}>
                    Registro médico
                  </label>
                  <input
                    placeholder='Escribir...'
                    className='register-inputs'
                    name='medical_record'
                    type='text'
                    maxLength={50}
                    onChange={({ target }) =>
                      onChangeSetData({ key: 'medical_record', value: target?.value })
                    }
                    value={data?.medical_record}
                  />
                </div>
              </Box>

              <Box>
                <label htmlFor='account_id' className={tableStyles.labelFont}>
                  Cuenta
                </label>
                <span className={`${tableStyles.ordOrangeText}`}>*</span>

                <SelectComponent
                  options={optionsSelect({
                    list: listAccounts?.results,
                    label: 'name',
                    value: 'id',
                  })}
                  value={valueSelect({
                    list: listAccounts?.results,
                    findId: 'id',
                    findLabel: 'name',
                    value: data?.account_id,
                  })}
                  onChange={({ value }) => onChangeSetData({ key: 'account_id', value })}
                />
              </Box>

              <Box mb={1}>
                <label htmlFor='account_id' className={tableStyles.labelFont}>
                  Perfiles
                </label>
                <span className={`${tableStyles.ordOrangeText}`}>*</span>

                <Select
                  isMulti
                  styles={customSelectMulti}
                  options={optionsSelect({
                    list: listProfiles?.data,
                    label: 'nombre',
                    value: 'id',
                  })}
                  value={data?.profile_list_temp?.length ? data?.profile_list_temp : ''}
                  placeholder='Seleccionar...'
                  onChange={e => {
                    onChangeSetData({ key: 'profile_list_temp', value: e });
                  }}
                />
              </Box>

              <Box>
                <label htmlFor='account_id' className={tableStyles.labelFont}>
                  Candidato
                </label>

                <SelectComponent
                  isDisabled={!data.account_id || !data.doc_num}
                  options={optionsSelect({
                    list: listCandidate?.results,
                    label: 'full_name',
                    value: 'id_candidate',
                  })}
                  value={valueSelect({
                    list: listCandidate?.results,
                    findId: 'id_candidate',
                    findLabel: 'full_name',
                    value: data?.candidate_id,
                  })}
                  onChange={({ value }) => onChangeSetData({ key: 'candidate_id', value })}
                />
              </Box>
            </Box>

            <Box>
              <Box display={'flex'} justifyContent='end' mb={1}>
                <div
                  className={`${tableStyles.colorOrange} ${tableStyles.hoverFilterDarkBlue} fw-bold pointer d-flex gap-2`}
                  onClick={() => addListTableModal()}
                >
                  <Box>Añadir</Box>
                  <img src={downloadArrow} alt='add' />
                </div>
              </Box>
              <GenericTable
                headers={tableHeaderModal}
                dark={true}
                style={{ margin: 0, marginBottom: '50px' }}
              >
                {tableBodyModal(data?.tableModalData)}
              </GenericTable>
            </Box>
          </Box>
        </ModalNew>

        <GenericTable headers={tableHeader} dark={true}>
          {tableBody(listUsers?.results)}
        </GenericTable>

        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {filters.page}
            {' de '}
            {Math.ceil(listUsers?.row_total / filters?.perpage)
              ? Math.ceil(listUsers?.row_total / filters?.perpage)
              : ''}{' '}
            ({listUsers?.row_total} encontrados)
          </p>

          <Pagination
            activePage={filters?.page}
            itemsCountPerPage={10}
            totalItemsCount={listUsers?.row_total}
            pageRangeDisplayed={5}
            onChange={val => {
              setData(state => ({
                ...state,
                currentPage: val,
                filters: {
                  ...state?.filters,
                  page: val,
                },
              }));

              getListUsers({
                token: token,
                url: `/users/params/`,
                objFilters: {
                  ...data.filters,
                  page: val,
                },
              });
            }}
            itemClassPrev={`${paginationStyles.itemClassPrev} `}
            itemClassNext={`${paginationStyles.itemClassNext} `}
            itemClassFirst={`${paginationStyles.itemClassFirst} `}
            itemClassLast={`${paginationStyles.itemClassLast} `}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </>
  );
};

export default TableUsers;
