import { Box, Button, Grid } from '@mui/material/';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import alertCircle from '../../../../assets/img/icons/alert-circle-orange.svg';
import backArrow from '../../../../assets/img/icons/atras-icon.svg';
import check from '../../../../assets/img/icons/check2.svg';
import CheckMark from '../../../../assets/img/icons/CheckMark.svg';
import rejectedIcon from '../../../../assets/img/icons/extraBtnRedX.svg';
import FalseCross from '../../../../assets/img/icons/FalseCross.svg';

import { loader } from '../../../../helpers/helpers';
import { useGetMethod, usePostMethod } from '../../../../Hooks/useFetch';

import EmployeeDetailAuth from '../../partners/EmployeeDetailAuth';
import { Interests, Severances } from './../tabs/';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'reactjs-popup/dist/index.css';
import tableStyles from '../../../../components/Layouts//tableStyle.module.scss';
import { customSwaltAlert } from '../../../../helpers/customSwaltAlert';
import '../../../TableUsers/tableUserStyle.scss';

export const Header = ({ dataPage, setTrigger }) => {
  const store = useSelector(state => state);
  const history = useHistory();

  const token = store.loginReducer.Authorization;
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'pplTm',
  );
  const idAccount = store.loginReducer.currentAccount.id;

  const [tab, setTab] = React.useState('severances');
  const [data, setData] = useState({
    trigger: 0,
    filters: {
      search: '',
      page: 1,
      perpage: 10,
      severanceId: dataPage?.detailPage?.severanceId,
      eaccount: idAccount,
      daysToPay: '',
    },
    modal: {
      show: false,
      isEditing: false,
      denial: '',
    },
  });
  const { filters, trigger } = data;

  const {
    results: severancePayrollResults,
    load: severanceAuthLoader,
    trigger: getSeverancePayroll,
  } = useGetMethod();

  const {
    trigger: getJobPosition,
    results: jobPositionsOptions,
    load: jobPositionsLoader,
  } = useGetMethod();

  useEffect(() => {
    getJobPosition({
      url: '/payroll/job_position/',
      objFilters: {
        entity_account: idAccount,
        onlyCurrent: 'current',
      },
      token: store.loginReducer.Authorization,
    });
  }, [getJobPosition, idAccount, store.loginReducer.Authorization]);

  const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/nomina/inicio');
    }

    getSeverancePayroll({
      url: '/payroll/severance/detail/',
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const [employeeDetail, setEmployeeDetail] = useState({
    dataPayroll: dataPage?.detailPage?.status?.name,
    show: false,
    dataEmployee: {},
    page: '',
  });

  const approveOrDenyPayroll = status => {
    customSwaltAlert({
      icon: 'warning',
      title: `<span style=color:#01A0F6>¿Está seguro?</span>`,
      text: `Se ${status === 'approved' ? 'autorizará' : 'denegará'} la planilla de cesantías`,
      confirmButtonText: `Si, Continuar`,
      showCancelButton: true,
    }).then(response => {
      if (response.isConfirmed) {
        updatePayroll({
          url: '/payroll/severance/',
          token: token,
          method: 'PUT',
          body: {
            severanceId: data?.filters?.severanceId,
            eaccount: idAccount,
            status: status,
          },
          succesAction: info => {
            setTrigger(state => ({ ...state, trigger: state.trigger + 1 }));
            dataPage?.setDetailPage(() => null);
            customSwaltAlert({
              title: `${status === 'approved' ? 'Autorizado' : 'Denegado'} exitosamente`,
              text: `${info.message}`,
              icon: 'success',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  return employeeDetail.show ? (
    <EmployeeDetailAuth
      employeeDetail={employeeDetail}
      setEmployeeDetail={setEmployeeDetail}
      dataIn={data}
      setData={setData}
    />
  ) : (
    <>
      {(severanceAuthLoader || updatePayrollLoader || jobPositionsLoader) && loader}
      <div className='w-80 mx-auto'>
        <h2 style={{ color: '#005dbf' }} className={`${tableStyles.title} fw-bold d-flex gap-3`}>
          <div>
            <img
              onClick={() => {
                dataPage?.setDetailPage(() => null);
              }}
              src={backArrow}
              height={'30px'}
              alt='backArrow'
              className={` pointer`}
            />
          </div>

          <div>
            <div className='pt-1'>{`${dataPage?.detailPage?.paymentYear}  / ${dataPage?.detailPage?.paymentYear}`}</div>
            {dataPage?.detailPage?.status?.name === 'review' ? (
              <div>
                <div
                  className='d-flex gap-2 align-items-center'
                  style={{ margin: '10px 0px 0px 5px', color: '#ff8b00' }}
                >
                  <span style={{ fontSize: '18px' }}>Revisión pendiente</span>
                  <img height={20} src={alertCircle} alt='alertCircle' />
                </div>
              </div>
            ) : dataPage?.detailPage?.status?.name === 'rejected' ? (
              <div>
                <div
                  className='d-flex gap-2 align-items-center'
                  style={{ margin: '10px 0px 0px 5px', color: 'rgba(236, 28, 36, 1)' }}
                >
                  <span style={{ fontSize: '18px' }}>Planilla rechazada</span>
                  <img height={15} src={rejectedIcon} alt='rejectedIcon' />
                </div>
              </div>
            ) : (
              dataPage?.detailPage?.status?.name === 'approved' && (
                <div>
                  <div
                    className='d-flex gap-2 align-items-center'
                    style={{ margin: '10px 0px 0px 5px', color: '#00a551' }}
                  >
                    <span style={{ fontSize: '18px' }}>Planilla autorizada</span>
                    <img height={20} src={check} alt='check' />
                  </div>
                </div>
              )
            )}
          </div>
        </h2>

        <Grid className='mt-2 mb-3' display={'grid'} gridTemplateColumns={'250px 250px 1fr'}>
          <Box
            className={
              tab === 'severances'
                ? tableStyles.headerBorderActive
                : tableStyles.headerBorderNoActive
            }
            onClick={() => setTab('severances')}
          >
            Cesantías
          </Box>
          <Box
            className={
              tab === 'interests'
                ? tableStyles.headerBorderActive
                : tableStyles.headerBorderNoActive
            }
            onClick={() => setTab('interests')}
          >
            Intereses
          </Box>
          <Box className={`${tableStyles.headerBorder}`}></Box>
        </Grid>
        {tab === 'severances' ? (
          <Severances
            dataPage={dataPage}
            data={data}
            setData={setData}
            jobPositionsOptions={jobPositionsOptions}
            severancePayrollResults={severancePayrollResults}
            setEmployeeDetail={setEmployeeDetail}
            employeeDetail={employeeDetail}
          />
        ) : (
          <Interests
            dataPage={dataPage}
            data={data}
            setData={setData}
            severancePayrollResults={severancePayrollResults}
            setEmployeeDetail={setEmployeeDetail}
            employeeDetail={employeeDetail}
          />
        )}
        <Grid className='d-flex justify-content-end gap-3 mt-3'>
          {dataPage?.detailPage?.status?.name !== 'review' ? (
            <>
              <Button
                style={{ textTransform: 'none' }}
                className={tableStyles.btnCancelStyle}
                onClick={() => {
                  dataPage.setDetailPage(() => null);
                }}
              >
                Cancelar
              </Button>
            </>
          ) : (
            <>
              <Button
                style={{ textTransform: 'none' }}
                className={tableStyles.btnCancelStyle}
                onClick={() => {
                  dataPage.setDetailPage(() => null);
                }}
              >
                Cancelar
              </Button>
              <Button
                style={{ textTransform: 'none' }}
                className={tableStyles.btnCancelStyle}
                onClick={() => approveOrDenyPayroll('rejected')}
              >
                <div className='d-flex gap-2'>
                  <img src={FalseCross} alt='FalseCross' />
                  <span>Denegar</span>
                </div>
              </Button>
              <Button
                style={{ textTransform: 'none' }}
                className={tableStyles.btnCancelStyle}
                onClick={() => approveOrDenyPayroll('approved')}
              >
                <div className='d-flex gap-2'>
                  <img src={CheckMark} alt='CheckMark' />
                  <span>Autorizar</span>
                </div>
              </Button>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};
