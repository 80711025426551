import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../Hooks/useFetch';

import downloadIcon from '../assets/img/icons/ordDownload.svg';
import { convertBase64, customSwaltAlertAsistencial, loader } from '../helpers';

import OrdModal from '../OrderingModule/OrderingComponents/OrdModal';

import tableStyles from '../../src/components/Layouts/tableStyle.module.scss';

export default function AssociateHistory({
  open = false,
  onClose = () => null,
  onSuccess = () => null,
  patientId,
  patientName,
}) {
  const token = useSelector(store => store.loginReducer.Authorization);
  const userId = useSelector(store => store.loginReducer.user_data.id);

  const [selectedOption, setSelectedOption] = useState(null);
  const fileInputRef = useRef(null);

  const { trigger: getScans, load: getScansLoader, results: scansResult, clear } = useGetMethod();
  const { trigger: getPdf, load: getPdfLoader } = useGetMethod();

  const { trigger: updateScan, load: updateScanLoader } = usePostMethod();
  const { trigger: updateScanHC, load: updateScanLoaderHC } = usePostMethod();

  useEffect(() => {
    if (open && patientId) {
      void getScans({
        url: `/medical/clinicHistory/${patientId}/scans`,
        token: token,
      });
    }
  }, [getScans, open, patientId, token]);

  const onCloseModal = () => {
    setSelectedOption(null);
    clear();
    onClose();
  };

  const onGetPdf = record => {
    void getPdf({
      url: `/medical/clinicHistory/previousMedicalHistory/`,
      objFilters: {
        cluId: patientId,
        record,
      },
      token: token,
      succesAction: result => {
        const url = result.results.key;

        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.rel = 'noreferrer noopener';

        anchor.click();
        anchor.remove();
      },
    });
  };

  const onSubmitScan = () => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se asociará la historia clínica escaneada del paciente: ${selectedOption.first_name} ${selectedOption?.other_names ?? ''} ${selectedOption.last_name}`,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
      noErrorALert: true,
      showCancelButton: true,
    }).then(answer => {
      if (answer.isConfirmed) {
        void updateScan({
          method: 'PUT',
          url: '/medical/generals/associate-sige',
          token: token,
          body: {
            patientId,
            sigeRecord: selectedOption.record,
            userId: userId,
          },
          succesAction: () => {
            customSwaltAlertAsistencial({
              icon: 'success',
              title: 'Asociación exitosa',
              text: 'Historia clínica asociada exitosamente.',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
            onSuccess();
            onCloseModal();
          },
          doAfterException: res => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: res?.message,
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      }
    });
  };

  const handleFileChangeHC = async event => {
    const file = event.target.files[0];
    if (file) {
      let encode = await convertBase64(file);
      let base64 = encode.split(',')[1];
      if (file.type === 'application/pdf') {
        onSubmitHCScanned(base64);
      } else {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Por favor, selecciona un archivo en formato PDF.',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    }
  };

  const onSubmitHCScanned = base64 => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se asociará la historia clínica escaneada al paciente: ${patientName} `,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(answer => {
      if (answer.isConfirmed) {
        updateScanHC({
          method: 'POST',
          url: `/medical/patient/${patientId}/assign-scanned-medical-records`,
          token: token,
          body: {
            base64,
          },
          succesAction: () => {
            customSwaltAlertAsistencial({
              icon: 'success',
              title: 'Asociación exitosa',
              text: 'Historia clínica asociada exitosamente.',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
            onSuccess();
            onCloseModal();
          },
          doAfterException: res => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: res?.message,
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      }
    });
  };
  const renderComponent = () => {
    return (
      <>
        {(getScansLoader || getPdfLoader || updateScanLoader || updateScanLoaderHC) && loader}
        <OrdModal
          title='Asociar HC escaneada'
          show={open}
          onHide={() => onCloseModal()}
          on
          btnYesName='Guardar'
          btnYesEvent={
            scansResult?.results?.hcsScanned?.length && !scansResult?.results?.hcUploads
              ? onSubmitScan
              : null
          }
          size={750}
          extraButtons={[
            {
              event: () => {
                fileInputRef.current.click();
              },
              name: 'Cargar',
              type: 4,
            },
          ]}
        >
          {scansResult?.results?.hcUploads && (
            <div className='row p-2 g-0 align-items-center border-bottom'>
              <div className='col'>
                <p className={`${tableStyles.ordDarkBlueText} m-0 fw-bold fs-5`}>
                  Record escaneado manualmente
                </p>
                <p className='text-muted m-0'>HC: &apos;{scansResult?.results?.hcUploads}&apos;</p>
              </div>
              <div className='col-1'>
                <img
                  src={downloadIcon}
                  className='pointer'
                  width={20}
                  onClick={() => {
                    onGetPdf();
                  }}
                  alt=''
                />
              </div>
            </div>
          )}
          <div className='w-100 py-2' style={{ maxHeight: 400, overflowY: 'auto' }}>
            <input
              type='file'
              accept='application/pdf'
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChangeHC}
            />
            {!!scansResult?.results?.hcUploads || !!scansResult?.results?.hcsScanned?.length ? (
              scansResult.results?.hcsScanned?.map(scan => (
                <div className='row p-2 g-0 align-items-center border-bottom' key={scan.record}>
                  {!scansResult?.results?.hcUploads && (
                    <div className='col-1'>
                      <input
                        type='radio'
                        className='form-check-input pointer'
                        name='hcScan'
                        id=''
                        onChange={() => {
                          setSelectedOption(scan);
                        }}
                        checked={selectedOption?.record === scan.record}
                      />
                    </div>
                  )}
                  <div className='col'>
                    <p className={`${tableStyles.ordDarkBlueText} m-0 fw-bold fs-5`}>{scan.arn}</p>
                    <p className='text-muted m-0'>Record: {scan.record}</p>
                  </div>
                  <div className='col-1'>
                    <img
                      src={downloadIcon}
                      className='pointer'
                      width={20}
                      onClick={() => {
                        onGetPdf(scan.record);
                      }}
                      alt=''
                    />
                  </div>
                </div>
              ))
            ) : (
              <p className='text-muted text-center'>
                No existen hojas escaneadas relacionadas al récord del paciente.
              </p>
            )}
          </div>
        </OrdModal>
      </>
    );
  };

  return renderComponent();
}
