import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import usePatientAdmissionStore from '../../Hooks/usePatientAdmissionStore';
import imgDiscount from '../../assets/img/icons/app_aprovado.svg';
import imgDiscountGray from '../../assets/img/icons/minusGray.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { formatteDocument, customSwaltAlertAsistencial } from '../../helpers';
import { getPermission, loader } from '../../helpers/helpers';
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';
import { OrdGenericTemplate } from './../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from './../../OrderingModule/OrderingComponents/OrdTable';
import { UseDayCalendar } from './../../components/DayCalendar/UseDayCalendar';
import { Button } from './../../components/UI/atoms/button/Button';

const PatientAdmission = () => {
  // --------------------------- States  -------------------------

  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const site = store.userReducer.site;
  const eaccount = store.loginReducer.currentAccount.id;
  const createdBy = store.loginReducer.user_data.id;
  const { colors } = useTheme();
  const history = useHistory();
  const listPermission = store.loginReducer.currentAccount?.profile?.permission;
  const myPermission = getPermission({ prefix: 'admitPat', listPermission });
  const idUser = store.loginReducer.user_data.id;
  const siteId = store.userReducer.site;
  const { setEvents, setActiveEvent, setImage, clearStateAdmissionPatient } =
    usePatientAdmissionStore();
  const [getCashClosingConfigSuccess, setGetCashClosingConfigSuccess] = useState(false);

  const { component: DayCalendar, getDaySelected } = UseDayCalendar();

  const [data, setData] = useState({
    selects: {
      listProfiles: null,
      listAccountsProfiles: null,
      listSites: null,
      listDoctors: [], // Estado para almacenar la lista de médicos
      selectedDoctor: '', // Estado para almacenar el médico seleccionado
    },
    table: {
      listDataTable: null,
      dataSelectedSize: 0,
      dataArrayOrder: null,
      filters: {
        page: 1,
        perpage: 10,
        pageRangeDisplayed: 3,
        search: '',
        admitted: 0,
        date: moment(getDaySelected).format('YYYY-MM-DD'),
        site,
        eaccount,
        createdBy,
        doctorId: '',
      },
      formatDataTable: null,
      trigger: 0,
    },
    modal: {
      isEditing: null,
      show: null,
    },
    dataSchema: [],
    dataArray: [],
  });
  const [selectedAppointments, setSelectedAppointments] = useState([]);

  const headers = [
    {
      title: '',
      className: `px-2 ${data?.table?.filters?.admitted ? '' : tableStyles.minWidth50px}`,
    },
    {
      title: 'Fecha',
      className: `px-2 fw-bold ${tableStyles.minWidth200px} text-center`,
    },
    {
      title: 'Paciente',
      className: `text-start ${tableStyles.minWidth300px} px-2 fw-bold`,
    },
    {
      title: 'Servicio',
      className: `text-start px-2 fw-bold ${tableStyles.minWidth300px}`,
    },
    {
      title: 'Sede',
      className: `text-center px-2 fw-bold ${tableStyles.minWidth300px}`,
    },
    {
      title: 'Hora',
      className: `text-start px-2 fw-bold ${tableStyles.minWidth150px} text-center`,
    },
    {
      title: 'Médico',
      className: `text-start px-2 fw-bold ${tableStyles.minWidth200px}`,
    },
    {
      title: 'Descuento',
      className: 'text-center px-2 fw-bold',
    },
    {
      title: 'Anticipo',
      className: 'text-center px-2 fw-bold',
    },
  ];

  //  ---------------------------Petitions -------------------------

  const { load: updateSiteLoader } = usePostMethod();

  const { load: loaderCreateNewSite } = usePostMethod();

  const {
    results: listDataTable,
    trigger: getListDataTable,
    load: loadListDataTable,
  } = useGetMethod();

  const {
    results: resultsCashClosingConfig,
    trigger: getCashClosingConfig,
    load: loadCashClosingConfig,
  } = useGetMethod();

  const {
    trigger: getAppointments,
    load: loadListAppointments
  } = useGetMethod();

  // Añadir nuevo método para obtener la lista de médicos
  const { trigger: fetchListDoctors } = useGetMethod();

  // Obtener la lista de médicos cuando el componente se monta
  useEffect(() => {
    fetchListDoctors({
      url: `/medical/patient-admission/medic`,
      objFilters: { eaccount },
      token: token,
    }).then(res => {
      if (res?.success) {
        // Mapear los datos para extraer medicInfo
        const doctors = res.results.map(item => item.medicInfo);
        // Filtrar los médicos duplicados
        const uniqueDoctors = Array.from(new Set(doctors.map(doctor => doctor.id))).map(id =>
          doctors.find(doctor => doctor.id === id),
        );
        setData(state => ({
          ...state,
          selects: { ...state.selects, listDoctors: uniqueDoctors },
        }));
      }
    });
  }, [eaccount, fetchListDoctors, token]);

  // //  --------------------------- Modal -------------------------
  const postModal = () => {
    setData(state => ({
      ...state,
      modal: { ...state.modal, show: true, isEditing: false },
      dataSchema: {
        userId: null,
        profileId: null,
        sites: [],
        eaccount,
        createdBy,
        actionType: 'post',
      },
    }));
  };

  //  --------------------------- Methods ------------------------------

  const selectedAction = async (id, patient) => {
    const isSelected = selectedAppointments.some(app => app.id === id);
    let options = [];

    if (isSelected) {
      options = selectedAppointments.filter(app => app.id !== id);
    } else {
      options = [...selectedAppointments, { id, patient, filters: data.table.filters }];
    }
    setSelectedAppointments(options);
  };

  // Método para manejar la selección del médico
  const handleDoctorChange = e => {
    const doctorId = e.value;
    setData(state => ({
      ...state,
      selects: { ...state.selects, selectedDoctor: doctorId },
      table: {
        ...state.table,
        filters: { ...state.table.filters, doctor: doctorId }, // Cambiar a doctor en lugar de doctorId
        trigger: state.table.trigger + 1,
      },
    }));
  };

  const doctorOptions = useMemo(
    () => [
      { label: 'Seleccionar médico...', value: '' },
      ...data.selects.listDoctors.map(doctor => ({
        label: doctor?.fullName,
        value: doctor.id,
      })),
    ],
    [data.selects.listDoctors],
  );
  // -------------------------------- Validations -----------------------
  const patientValidation = async () => {
    if (
      resultsCashClosingConfig?.results?.cashClosingStatus === 'closed' ||
      getCashClosingConfigSuccess === false
    ) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `No se encontró configuración de cierres para la cuenta en sesión`,
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    } else {
      const selectedAppIds = selectedAppointments.map(
        appInfo => appInfo.id
      ).join(",")

      await getAppointments({
        url: "/medical/patientAdmission/",
        token: token,
        objFilters: { ...data.table.filters, appIds: selectedAppIds, perpage: undefined },
        doAfterSuccess: (res) => {
          const appsInfo = res?.results?.length > 0 ? res.results : []

          setEvents(appsInfo)
          setActiveEvent({ ...appsInfo.at(0), progress: 1 })

          history.push({ pathname: '/admision/admitirPacientes/informacionBasica' });
        }
      })
    }
  };

  //-------------- format data to table 🍕   --------------------------
  const formatData = () => {
    return listDataTable?.results?.map(el => [
      !data?.table?.filters?.admitted
        ? {
            text: (
              <input
                type='checkbox'
                name='handleTelemedicine'
                className={` ${tableStyles.checkClearBlue} ${tableStyles.appCheckInput}    ${tableStyles.appCheckClear}  form-check-input p1 mx-4`}
                checked={selectedAppointments.some(app => app.id === el?.id)}
                disabled={
                  selectedAppointments.length && selectedAppointments[0].patient !== el?.patient?.id
                }
                onChange={() => selectedAction(el?.id, el?.patient.id)}
              />
            ),
            selected: selectedAppointments.some(app => app.id === el?.id),
            className: 'text-start px-2',
          }
        : {
            text: <div></div>,
            className: 'px-2',
            selected: selectedAppointments.some(app => app.id === el?.id),
          },
      {
        text: <div>{el.date || '-'}</div>,
        className: 'text-center px-2',
        selected: selectedAppointments.some(app => app.id === el?.id),
      },
      {
        text: (
          <div>
            <div>{`${el.patient.firstName} ${el.patient.lastName}`}</div>
            <div style={{ color: colors.darkGray2 }}>
              {el.patient.docTypeDescription} {formatteDocument(el.patient.document).format}
            </div>
          </div>
        ),
        className: 'text-start px-2',
        selected: selectedAppointments.some(app => app.id === el?.id),
      },
      {
        text: <div>{el.service.name || '-'}</div>,
        selected: selectedAppointments.some(app => app.id === el?.id),
        className: 'text-start px-2',
      },
      {
        text: <div>{el.siteName || '-'}</div>,
        selected: selectedAppointments.some(app => app.id === el?.id),
        className: 'text-center px-2',
      },
      {
        text: <div>{moment(el.hour, 'hh:mm').format('h:mm a') || '-'}</div>,
        selected: selectedAppointments.some(app => app.id === el?.id),
        className: 'text-center px-2',
      },
      {
        text: <div>{el.fullNameDoctor || '-'}</div>,
        selected: selectedAppointments.some(app => app.id === el?.id),
        className: 'text-start px-2',
      },

      {
        text: (
          // <div>{el.service.discount}</div>,
          <div>
            {el.discount < 1 || !el.discount ? (
              <img alt='imgDiscountGray' src={imgDiscountGray} height={26} />
            ) : (
              <img alt='imgDiscount' src={imgDiscount} height={22} />
            )}
          </div>
        ),
        selected: selectedAppointments.some(app => app.id === el?.id),
        className: 'text-center px-2',
      },
      {
        text: (
          <div>
            {el.service.advance < 1 || !el.service.advance ? (
              <img alt='imgDiscountGray' src={imgDiscountGray} height={26} />
            ) : (
              <img alt='imgDiscount' src={imgDiscount} height={22} />
            )}
          </div>
        ),
        selected: selectedAppointments.some(app => app.id === el?.id),
        className: 'text-center px-2',
      },
    ]);
  };

  //-------------- useEffect 🍕   --------------------------
  useEffect(() => {
    if (data.table.trigger) {
      if (
        data.table.filters.search ||
        data.table.filters.admitted !== null ||
        data.table.filters.date !== null
      ) {
        getListDataTable({
          url: `/medical/admissions`,
          objFilters: { ...data.table.filters, medicId: data.table.filters.doctor },
          token: token,
        });
      } else {
        getListDataTable({
          url: `/medical/admissions`,
          objFilters: data.table.filters,
          token: token,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.table.trigger, data.table.filters.page]);

  useEffect(() => {
    setData(state => ({
      ...state,
      table: {
        ...state.table,
        trigger: data.table.trigger + 1,
        filters: {
          ...state.table.filters,
          date: moment(getDaySelected).format('YYYY-MM-DD'),
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDaySelected]);

  const closingPaymentState = {
    group: 'Portal Asistencial',
    catDetail: {
      title: 'Módulo de Admisión',
      url: '/admision/inicio',
      home_img: store.loginReducer.newCategories
        .find(e => e.description === 'Portal Asistencial')
        ?.data.find(e => e.url === 'admision')?.home_img,
    },
  };

  useEffect(() => {
    getCashClosingConfig({
      url: `/medical/admissions/cashClosingConfig/`,
      objFilters: { userId: idUser, siteId, eaccount },
      token: token,
    }).then(res => {
      setGetCashClosingConfigSuccess(res?.success || false);
      if (res?.success) {
        if (res?.results?.hasPendingCashClosing) {
          customSwaltAlertAsistencial({
            icon: 'warning',
            title: `Intenta de nuevo`,
            text: 'Para poder seguir admitiendo pacientes, es necesario que realice un cierre parcial',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
          }).then(() => {
            history.push({ pathname: 'inicio', state: closingPaymentState });
          });
        }
      } else {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: `Intenta de nuevo`,
          text: res?.message,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
      }
    });

    if (!myPermission?.read) {
      history.push({ pathname: 'inicio', state: closingPaymentState });
    } else {
      getListDataTable({
        url: `/medical/patientAdmission/`,
        objFilters: data.table.filters,
        token: token,
      });

      setImage('');
    }

    clearStateAdmissionPatient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //-------------- Component --------------------------

  return (
    <>
      {(loaderCreateNewSite || loadListDataTable || updateSiteLoader || loadCashClosingConfig || loadListAppointments) &&
        loader}

      <OrdGenericTemplate
        className={`w-90 mt-4 mx-2`}
        showBottomBorder={false}
        title={'Admitir pacientes'}
        titleSize={12}
        selectComponent={{
          options: doctorOptions,
          onChange: handleDoctorChange,
          value: doctorOptions.find(option => option.value === data.selects.selectedDoctor),
        }}
        searchWithNew={{
          searchWith: '70',
          searchIcon: true,
          onClickSearchIcon: () => {
            setData(state => ({
              ...state,
              table: { ...state.table, trigger: data.table.trigger + 1 },
            }));
          },
          onChange: e => {
            setData(state => ({
              ...state,
              table: {
                ...state.table,
                filters: { ...state.table.filters, search: e.target.value },
              },
            }));
          },
          buttonNewAction: () => postModal(),
          onSubmit: e => {
            e.preventDefault();
            setData(state => ({
              ...state,
              table: { ...state.table, trigger: data.table.trigger + 1 },
            }));
          },
        }}
        filterCheck={{
          label: 'Mostrar citas admitidas',
          classNameLabel: '1',
          classNameInput: '0',
          onChange: ({ target }) => {
            setData(state => ({
              ...state,
              table: {
                ...state.table,
                trigger: data.table.trigger + 1,
                filters: { ...state.table.filters, admitted: target.checked ? 1 : 0 },
                dataSelectedSize: false,
              },
            }));
          },
        }}
        filterDate={{
          component: DayCalendar,
        }}
      >
        {!listDataTable?.results?.length ? (
          <Alert
            key={'primary'}
            style={{ border: `1px solid #00b4cc ` }}
            className='text-center fw-bold mt-5 w-50 m-auto'
          >
            <span className={tableStyles.ordAquaMarineText}>No hay resultados encontrados.</span>
          </Alert>
        ) : (
          <>
            <OrdTable
              headers={headers}
              body={formatData()}
              className={`${tableStyles.ordTableShadow}`}
              selectedBg={`${tableStyles.selectedBg}`}
              paginate={{
                activePage: data.table?.filters?.page,
                totalPages: listDataTable?.rowTotal || 3,
                perPage: data.table?.filters?.perpage,
                pageRangeDisplayed: data.table?.filters?.pageRangeDisplayed,
                onChangePage: async e => {
                  setData(state => ({
                    ...state,
                    table: { ...state.table, filters: { ...state.table.filters, page: e } },
                  }));
                },
                showTextDetails: true,
              }}
            />

            {(myPermission?.create || myPermission?.update) && (
              <Box className='d-flex justify-content-end'>
                <Button
                  className={`${tableStyles}.ordBtnPrimary} btn`}
                  disabled={
                    moment(getDaySelected).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') ||
                    !selectedAppointments.length
                  }
                  width='auto'
                  padding='8px'
                  fontSize='14px'
                  border='none'
                  backgroundColor={colors.ordAquaMarine}
                  backgroundColorHover={colors.ordAquaMarine + '90'}
                  onClick={patientValidation}
                >
                  Admitir paciente
                </Button>
              </Box>
            )}
          </>
        )}
      </OrdGenericTemplate>
    </>
  );
};

export default PatientAdmission;
