// espera un array de json con los siguientes keys
// amount , discount, advance
export const calculateCollectionOfAdmissionPatient = (
  services = [],
  amountTransfer = 0,
) => {
  if (!Array.isArray(services) || !services?.length) {
    return { message: "Digite un array correcto" };
  }

  const result = {
    amount: 0,
    discount: 0,
    advance: 0,
    price: 0,
    creditAmount: 0
  };

  let amountTotal, amountTotalWithoutTotalBalance,amountTotalFixed;

  for (let index = 0; index < services?.length; index++) {
    const service = services[index];
    if (service["amount"] !== undefined && service["amount"] !== null) {
      result.amount += Number(service.amount);
    }

    if (service["discount"] !== undefined && service["discount"] !== null) {
      result.discount += Number(service.discount);
    }

    if (service["price"] !== undefined && service["price"] !== null) {
      result.price += Number(service.price);
    }

    if (service["relatedServicesAmount"] !== undefined && service["relatedServicesAmount"] !== null) {
      result.price += Number(service.relatedServicesAmount);
    }

    if (service["relatedServicesPatientAmount"] !== undefined && service["relatedServicesPatientAmount"] !== null) {
      result.amount += Number(service.relatedServicesPatientAmount);
    }

    if (service["servicesChildrenAmount"] !== undefined && service["servicesChildrenAmount"] !== null) {
      result.amount += Number(service.servicesChildrenAmount);
    }

    if (service["servicesChildrenPrice"] !== undefined && service["servicesChildrenPrice"] !== null) {
      result.price += Number(service.servicesChildrenPrice);
    }

    if (service?.["creditAmount"] !== undefined && service["creditAmount"] !== null) {
      result.creditAmount += Number(service.creditAmount);
    }
  }

  result.advance = Number(amountTransfer)

  const totalDiscount = result.discount;
  const totalAdvance = result.advance;

  amountTotalFixed = result.amount;

  if (Number(result.price) <= (Number(totalDiscount) + Number(totalAdvance))) {
    amountTotal = 0;
  } else {
    amountTotal = result.amount - Number(totalAdvance);
  }

  if (Number(result.amount) <= (Number(totalDiscount) + Number(totalAdvance))) {
    amountTotalWithoutTotalBalance = 0;
  } else {
    amountTotalWithoutTotalBalance = amountTotalWithoutTotalBalance =
      result.amount - (totalDiscount + Number(totalAdvance));
  }

  const coverage = result.price - amountTotal - result.discount;
  const remainingAmountBeforePayment = amountTotal - result.creditAmount;
  const remainingAmount = amountTotal - result.creditAmount;

  return {
    ...result,
    amountTotal,
    amountTotalWithoutTotalBalance,
    amountTotalFixed,
    coverage,
    creditAmount: result.creditAmount,
    remainingAmount,
    remainingAmountBeforePayment
  };
};
