import { Col, Row, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import backArrow from '../../../assets/img/icons/iconBack.svg';
import { Fragment, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loader, message } from '../../../helpers/helpers';
import { usePostMethod } from '../../../Hooks/useFetch';
import CardComponent from './CardComponent/CardComponent';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';
import _ from 'lodash';

const NewRol = () => {
  const location = useLocation();
  const store = useSelector(state => state);
  const history = useHistory();
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const modulesInfo = location?.state?.data ?? {};
  const dataEdit = location?.state?.dataEdit ?? {};
  const isEditing = location?.state?.isEditing ?? false;
  const idProfile = location?.state?.idProfile;
  const { load: createRolLoader, trigger: createRolMethod } = usePostMethod();
  const goBackFunction = () => {
    history.push({
      state: { data: modulesInfo, idProfile: idProfile, isEditingAgain: isEditing },
      pathname: '/administracion/GestionDePermisos/NuevoRol',
    });
  };
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'gespermiss',
  );
  const [data, setData] = useState({
    rolName: '',
    modules: [],
  });
  const permissions = [
    { label: 'Crear', value: 'create' },
    { label: 'Leer', value: 'read' },
    { label: 'Editar', value: 'edit' },
    { label: 'Eliminar', value: 'delete' },
    { label: 'Aprobar', value: 'approve' },
    { label: 'Rechazar', value: 'reject' },
  ];
  useEffect(() => {
    if (!Object.keys(modulesInfo).length) {
      history.push('/administracion/GestionDePermisos/');
    }
    if (!myPermission?.read) {
      history.push('/categoryselect');
    }
    if (modulesInfo?.modules?.length > 0) {
      let tempArr = [];
      modulesInfo?.modules?.forEach(mod => {
        tempArr.push({ idModule: mod.id, sections: [] });
        setData({ rolName: modulesInfo.rolName, modules: tempArr });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEnterprise]);

  useEffect(() => {
    if (isEditing) {
      let tempArr = [];

      modulesInfo.modules.forEach(mod => {
        if (dataEdit.some(x => x.id === mod.id)) {
          let tempArrModule = [];
          dataEdit.forEach(m => {
            let tempArrSections = [];
            m.sections.forEach(s => {
              let tempArrPage = [];
              s.pages.forEach(p => {
                tempArrPage.push({
                  idPage: p.idPage,
                  read: p.permissions.read,
                  create: p.permissions.create,
                  edit: p.permissions.edit,
                  delete: p.permissions.delete,
                  approve: p.permissions.approve,
                  reject: p.permissions.reject,
                });
              });
              tempArrSections.push({ idSection: s.idSection, pages: tempArrPage });
            });
            tempArrModule.push({ idModule: m.id, sections: tempArrSections });
          });

          tempArr.push(tempArrModule.find(x => x.idModule === mod.id));
        } else {
          tempArr.push({ idModule: mod.id, sections: [] });
        }
      });
      setData({ ...data, modules: tempArr, rolName: modulesInfo.rolName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckAllModule = (checked, module) => {
    const currentModuleIndex = data.modules.findIndex(mod => mod.idModule === module.id);

    if (currentModuleIndex === -1) return;

    const updatedModule = {
      ...data.modules[currentModuleIndex],
      sections: module.sections.map(section => ({
        ...section,
        pages: section.pages.map(page => ({
          ...page,
          read: checked ? 1 : 0,
          create: checked ? 1 : 0,
          edit: checked ? 1 : 0,
          delete: checked ? 1 : 0,
          approve: checked ? 1 : 0,
          reject: checked ? 1 : 0,
        })),
      })),
    };

    const updatedModules = [...data.modules];
    updatedModules[currentModuleIndex] = updatedModule;

    setData({ ...data, modules: updatedModules });
  };

  const onChangeCheckBox = (isChecked, section, module) => {
    const updatedModules = data.modules.map(mod => {
      if (mod.idModule === module.id) {
        return {
          ...mod,
          sections: mod.sections.map(sec => {
            if (sec.idSection === section.idSection) {
              return {
                ...sec,
                pages: sec.pages.map(page => ({
                  ...page,
                  read: isChecked ? 1 : 0,
                  create: isChecked ? 1 : 0,
                  edit: isChecked ? 1 : 0,
                  delete: isChecked ? 1 : 0,
                  approve: isChecked ? 1 : 0,
                  reject: isChecked ? 1 : 0,
                })),
              };
            }
            return sec;
          }),
        };
      }
      return mod;
    });
    setData({ ...data, modules: updatedModules });
  };

  const onChangePage = (isChecked, page, section, module) => {
    const updatedModules = data.modules.map(mod => {
      if (mod.idModule === module.id) {
        return {
          ...mod,
          sections: mod.sections.map(sec => {
            if (sec.idSection === section.idSection) {
              const updatedPages = sec.pages.map(pag => {
                if (pag.idPage === page.idPage) {
                  return {
                    ...pag,
                    read: isChecked ? 1 : 0,
                    create: isChecked ? 1 : 0,
                    edit: isChecked ? 1 : 0,
                    delete: isChecked ? 1 : 0,
                    approve: isChecked ? 1 : 0,
                    reject: isChecked ? 1 : 0,
                  };
                }
                return pag;
              });

              return {
                ...sec,
                pages: updatedPages,
              };
            }
            return sec;
          }),
        };
      }
      return mod;
    });

    setData({ ...data, modules: updatedModules });
  };

  const onChangePermission = (isChecked, page, section, module, permission) => {
    const updatedModules = data.modules.map(mod => {
      if (mod.idModule !== module.id) return mod;

      return {
        ...mod,
        sections: mod.sections.map(sec => {
          if (sec.idSection !== section.idSection) return sec;

          const pageExists = sec.pages.some(p => p.idPage === page.idPage);

          return {
            ...sec,
            pages: pageExists
              ? sec.pages.map(pag => {
                  if (pag.idPage === page.idPage) {
                    return { ...pag, [permission.value]: isChecked ? 1 : 0 };
                  }
                  return pag;
                })
              : [
                  ...sec.pages,
                  {
                    idPage: page.idPage,
                    read: 0,
                    create: 0,
                    edit: 0,
                    delete: 0,
                    approve: 0,
                    reject: 0,
                    [permission.value]: isChecked ? 1 : 0,
                  },
                ],
          };
        }),
      };
    });
    setData({ ...data, modules: updatedModules });
  };

  const createRol = method => {
    let tempArr = [];
    let status = true;
    data.modules.forEach(mod => {
      let moduleObject = modulesInfo?.modules.find(x => x.id === mod.idModule);
      if (mod.sections.length > 0) {
        mod.sections.forEach(section => {
          let sectionObject = moduleObject?.sections.find(x => x.idSection === section.idSection);
          if (section.pages.length > 0) {
            section.pages.forEach(page => {
              let pageObject = sectionObject?.pages.find(x => x.idPage === page.idPage);
              let permissionsFilter = [
                page.read,
                page.create,
                page.edit,
                page.delete,
                page.approve,
                page.reject,
              ];
              if (permissionsFilter.some(x => x === 1)) {
                tempArr.push({
                  idPage: page.idPage,
                  permissions: {
                    read: page.read,
                    create: page.create,
                    edit: page.edit,
                    delete: page.delete,
                    approve: page.approve,
                    reject: page.reject,
                  },
                });
              } else {
                message(
                  'info',
                  'Verificar módulos ',
                  `Verifique que en el módulo <b>'${moduleObject.name}'</b>, en la sección <b>'${sectionObject.nameSection}'</b>, en la página <b>'${pageObject.namePage}'</b> tenga asociado permisos`,
                  undefined,
                  true,
                );
                status = false;
              }
            });
          } else {
            message(
              'info',
              'Verificar módulos ',
              `Verifique que en el módulo <b>'${moduleObject.name}'</b>, en la sección <b>'${sectionObject.nameSection}'</b>  tenga asociado como mínimo una página con sus respectivos permisos`,
              undefined,
              true,
            );
            status = false;
          }
        });
      } else {
        message(
          'info',
          'Verificar módulos ',
          `Verifique que el módulo <b>'${moduleObject.name}'</b> tenga asociado como mínimo una sección y página con sus respectivos permisos`,
          undefined,
          true,
        );
        status = false;
      }
    });

    let body = {
      rolName: data.rolName,
      pages: tempArr,
      eaccount: idEnterprise,
    };

    if (method === 'edit') {
      body.idRol = idProfile;
    }

    if (status) {
      createRolMethod({
        url: '/security/permissions_by_profile/',
        method: isEditing ? 'PUT' : 'POST',
        body: body,
        token: token,
        succesAction: info => {
          handleBack();
          customSwaltAlert({
            icon: 'success',
            title: info.title,
            text: info.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
        doAfterException: error => {
          customSwaltAlert({
            icon: 'error',
            title: 'Intenta de nuevo',
            text: error.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
      });
    }
  };

  const handleBack = () => {
    history.push('/administracion/GestionDePermisos');
  };

  const sectionsData = useMemo(() => {
    return data.modules.flatMap(module => module.sections);
  }, [data.modules]);

  const pagesData = sectionsData.flatMap(section => section.pages);

  const allChecked = moduleId => {
    const module = data.modules.find(mod => mod.idModule === moduleId);

    if (module) {
      const modulePages = module.sections.flatMap(section => section.pages);
      return modulePages.every(
        page =>
          !!page.read &&
          !!page.create &&
          !!page.edit &&
          !!page.delete &&
          !!page.approve &&
          !!page.reject,
      );
    }
  };

  const bodyCard = module => {
    const currentModule = data?.modules?.find(e => e?.idModule === module?.id);
    const isCheckedAll = allChecked(module.id);

    return (
      <table className='table mt-4'>
        <thead>
          <tr className={`align-middle ${tableStyles.theadLight}`}>
            <th className='p-2'>
              <input
                className={`border border-dark-blue form-check-input check-dark-blue`}
                type='checkbox'
                style={{ width: '18px', height: '18px' }}
                checked={isCheckedAll}
                onChange={({ target }) => onCheckAllModule(target.checked, module)}
              />
            </th>
            <th className='p-2 fs-6'>{module.name}</th>
            {permissions.map(permission => (
              <th key={permission.value} className='p-2 text-center fs-6'>
                {permission.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {module.sections.map(section => {
            const sectionPages = currentModule?.sections?.find(
              sec => sec.idSection === section.idSection,
            );

            const isChecked = sectionPages?.pages?.every(
              page =>
                !!page.read &&
                !!page.create &&
                !!page.edit &&
                !!page.delete &&
                !!page.approve &&
                !!page.reject,
            );

            return (
              <Fragment key={section.idSection}>
                <tr className='align-middle bg-light'>
                  <td width={40} className='p-2'>
                    <input
                      className={`border border-dark-blue form-check-input check-dark-blue`}
                      type='checkbox'
                      style={{ width: '18px', height: '18px' }}
                      checked={isChecked}
                      onChange={({ target }) => onChangeCheckBox(target.checked, section, module)}
                    />
                  </td>
                  <td className='fw-bold p-2'>{section.nameSection}</td>
                  {permissions.map(permission => (
                    <td key={permission.value} className='text-center p-2' width={90}></td>
                  ))}
                </tr>
                {section.pages.map(page => {
                  const pageWithData = _.find(pagesData, ['idPage', page.idPage]);

                  const isPageChecked =
                    !!pageWithData?.approve &&
                    !!pageWithData?.create &&
                    !!pageWithData?.delete &&
                    !!pageWithData?.edit &&
                    !!pageWithData?.read &&
                    !!pageWithData?.reject;

                  return (
                    <tr key={page.idPage} className='align-middle'>
                      <td width={40} className='py-1 px-2'>
                        <input
                          className={`border border-dark-blue form-check-input check-dark-blue`}
                          type='checkbox'
                          style={{ width: '18px', height: '18px' }}
                          checked={isPageChecked}
                          onChange={({ target }) =>
                            onChangePage(target.checked, page, section, module)
                          }
                        />
                      </td>
                      <td width={220} className='py-1 px-2'>
                        {page.namePage}
                      </td>
                      {permissions.map(permission => (
                        <td key={permission.value} className='text-center'>
                          <input
                            className={`border border-dark-blue form-check-input check-dark-blue`}
                            type='checkbox'
                            checked={!!pageWithData?.[permission.value]}
                            onChange={({ target }) =>
                              onChangePermission(target.checked, page, section, module, permission)
                            }
                            style={{ width: '18px', height: '18px' }}
                          />
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {createRolLoader && loader}
      <div className='w-80 mx-4'>
        <Row>
          <Col xs={10}>
            <h1 className={`${tableStyles.title} d-flex`}>
              <img
                onClick={() => goBackFunction()}
                src={backArrow}
                height={32}
                width={32}
                alt='icon'
                className={`mr-3 cursorPointer`}
                style={{ marginTop: '10px' }}
              />
              <div className='flex-column'>
                <div>{isEditing ? 'Editar rol' : 'Nuevo rol'}</div>
                <div
                  className={`${tableStyles.f16} ${tableStyles.darkBlueText}  `}
                  style={{ fontWeight: 'normal ' }}
                >
                  Configuración de permisos y restricciones de los sub-módulos
                </div>
              </div>
            </h1>
          </Col>
        </Row>
        <div className='mx-4'>
          <Row className='my-4' style={{ marginLeft: '10px' }}>
            <div className='px-2 ml-2'>
              {modulesInfo?.modules?.length > 0 &&
                modulesInfo?.modules?.map((e, key) => {
                  return (
                    <CardComponent
                      key={key}
                      title={e.name}
                      subtitle={e.subtitle}
                      body={bodyCard(e)}
                    />
                  );
                })}
            </div>
          </Row>
          <Row>
            <div className={`customTabs__Footer mt-4 ml-1`}>
              <Button
                onClick={() => createRol(isEditing ? 'edit' : 'create')}
                className={tableStyles.btnPrimary}
              >
                {isEditing ? 'Editar' : 'Crear'}
              </Button>

              <Button
                variant='outline-primary'
                onClick={() => handleBack()}
                className={tableStyles.btnSecondary}
              >
                Cancelar
              </Button>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default NewRol;
