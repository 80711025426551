//react
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

//styles
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import styles from './styles.module.scss';

//hooks
import { useGetMethod, usePostMethod } from '../../../Hooks';

//utils
import moment from 'moment';
import {
  convertLocalDate,
  customSwaltAlertAsistencial,
  generateId,
  isEmptyOrUndefined,
  loader,
  validateEmail,
} from '../../../helpers';

// DatePicker
import es from 'date-fns/locale/es';
import DatePicker from 'react-datepicker';

//generals components
import { AddCircleOutlineRounded, DeleteOutline } from '@material-ui/icons';
import { Box, Tooltip } from '@mui/material';

//locals components
import {
  RenderPdf,
  SearchAppointment,
  SearchAppointmentCarousel,
  SearchCardDoctor,
} from './components';

//components
import { Form } from 'react-bootstrap';
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';

const DEFAULT_OPTION = { label: 'Seleccionar...', value: null };
const SELECTED_DATE_INITAL_STATE = {
  currentDate: moment().format('YYYY-MM-DD'),
  scheduleId: null,
  schedules: [],
  doctor: null,
};
const APPOINTMENT_HEADER = [
  { title: 'Servicio', className: 'px-2 text-start' },
  { title: 'Fecha cita', className: 'px-2 text-start' },
  { title: 'Hora cita', className: 'px-2 text-start' },
  { title: 'Sede', className: 'px-2 text-start' },
  { title: 'Médico', className: 'px-2 text-start' },
  { title: '', className: 'px-2 text-end' },
];

export const Schedule = ({
  token,
  contractId,
  corporateClientId,
  populationId,
  detailPatient,
  companyType,
  popId,
  wsValidated,
  crtName,
  crtId,
  attGroupId,
  postTicketFunction,
  setTicketValue,
  insureSelected,
  goBackFunction,
  order,
}) => {
  //======== STATES LOCALS =========//
  const store = useSelector(state => state);
  const modality = store.filterCheckTeleScheduleReducer.checked ? 'tmed' : 'on_site';
  const idAccount = store.loginReducer.currentAccount.id;
  const idUser = store.loginReducer.user_data.id;
  const fullNamePatient = `${detailPatient?.results?.firstName} ${detailPatient?.results?.otherNames} ${detailPatient?.results?.lastName} ${detailPatient?.results?.secondSurname}`;
  const bussines_name = store?.loginReducer?.currentAccount?.bussines_name;

  const [modalReminder, setModalReminder] = useState({
    appId: '',
    cluEmail: {
      inputValue: '',
      value: [],
    },
    emailSubject: 'Recordatorio de cita',
    emailBody:
      insureSelected.isMain === 0
        ? `<p>Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir! </p>`
        : `<p> Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir!</p>`,
    fileBase64: '',
    show: false,
  });
  const [enabledRequestExtraQuote, setEnabledRequestExtraQuote] = useState(false);

  const [stateInputReminder, setStateInputReminder] = useState({
    inputValue: '',
    value: [
      { label: `${detailPatient?.results?.email}`, value: `${detailPatient?.results?.email}` },
    ],
  });

  const stateInput = {
    inputValue: '',
    value: [],
  };

  //======== STATE IMPORTANT =========//
  const [extraQuoteModal, setExtraQuoteModal] = useState({ open: false, reason: '' });
  const [filters, setFilters] = useState({
    service: DEFAULT_OPTION,
    location: DEFAULT_OPTION,
    site: DEFAULT_OPTION,
    doctor: DEFAULT_OPTION,
    sorId: null,
  });
  const [selectedDate, setSelectedDate] = useState(SELECTED_DATE_INITAL_STATE);
  const [selectedTime, setSelectedTime] = useState({
    startTime: '',
    endTime: '',
  });
  const [appointments, setAppointments] = useState([]);
  const [observations, setObservations] = useState('');
  const [extraQuotaSpacesAvailable, setExtraQuotaSpacesAvailable] = useState(true);

  //========GET HTTPS=========//
  const {
    load: loaderListServices,
    trigger: getListServices,
    results: listServicesResult,
  } = useGetMethod();

  const {
    load: loaderListLocations,
    trigger: getListLocations,
    results: listLocationsResult,
  } = useGetMethod();

  const {
    load: loaderListDoctors,
    trigger: getListDoctors,
    results: listDoctorsResult,
  } = useGetMethod();

  const {
    load: loaderScheduleDoctor,
    trigger: getScheduleDoctor,
    results: listSchedulesResult,
    clear: clearSchedules,
  } = useGetMethod();

  const {
    load: loaderScheduleMonth,
    trigger: getScheduleMonth,
    results: listSchedulesMonthResult,
    clear: clearSchedulesMonth,
  } = useGetMethod();

  const { trigger: checkExtraQuotaAvailability } = useGetMethod();

  const { load: loaderTicket, trigger: getTicket } = useGetMethod();

  const { load: loaderCiteAppointment, trigger: getCiteAppointment } = useGetMethod();

  //========POST HTTPS=========//
  const { load: loaderCreateAppointment, trigger: createAppointmentMethod } = usePostMethod();

  const { load: loaderPostSmartQue, trigger: createSmartQueMethod } = usePostMethod();

  const { load: requestQuotaLoader, trigger: requestQuotaMethod } = usePostMethod();

  const { load: createReminderLoader, trigger: createReminderMethod } = usePostMethod();

  const serviceOptions = useMemo(() => {
    if (listServicesResult?.results) {
      const options = listServicesResult.results.map(option => ({
        label: option.srvName,
        value: option.srvId,
      }));
      return options;
    }
    return [];
  }, [listServicesResult?.results]);

  const locationOptions = useMemo(() => {
    if (listLocationsResult?.results) {
      const options = listLocationsResult.results.map(option => ({
        label: option.name,
        value: option.id,
        sites: option.sites,
      }));
      return options;
    }
    return [];
  }, [listLocationsResult?.results]);

  const doctorOptions = useMemo(() => {
    if (listDoctorsResult?.results) {
      const options = listDoctorsResult.results.map(option => ({
        label: option.mdName,
        value: option.mdId,
      }));
      return [DEFAULT_OPTION, ...options];
    }
    return [];
  }, [listDoctorsResult?.results]);

  const scheduleOptions = useMemo(() => {
    if (listSchedulesResult?.results) {
      return listSchedulesResult?.results?.map(available => ({
        ...available,
        schedule: available?.schedule?.map(sch => ({
          ...sch,
          hours:
            sch?.hours?.map(hour => ({
              ...hour,
              virtualAppointment: hour?.appointment,
            })) || [],
        })),
      }));
    }
    return [];
  }, [listSchedulesResult?.results]);

  const proximateDates = useMemo(() => {
    if (listSchedulesResult?.proximateDates) {
      return listSchedulesResult.proximateDates.map(date => {
        return moment(date).toDate();
      });
    }
    return [];
  }, [listSchedulesResult?.proximateDates]);

  //======== EFFECTS =========//  Aquí es el
  useEffect(() => {
    void getListServices({
      token,
      url: '/medical/appointment/serviceEntity/',
      objFilters: {
        popId,
        modality,
        contractId,
        companyType,
        idAccount,
        cclientId: corporateClientId,
        type: 'consultation,diagnostic_aids,procedure',
      },
      succesAction: res => {
        const format = res?.results?.map(service => ({
          ...service,
          label: service?.srvName,
          value: service.srvId,
        }));
        const serviceValue = order.serviceId
          ? format.find(service => service?.value === order?.serviceId) ?? null
          : null;

        if (serviceValue) {
          onChangeSelectOfService({
            serviceId: serviceValue.value,
            serviceName: serviceValue.label,
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modality]);

  //======== FUNCTIONS =========//

  const createOptionReminder = label => ({
    label,
    value: label,
  });

  const closeModalReminder = () => {
    setModalReminder({
      appId: '',
      cluEmail: {
        inputValue: '',
        value: [],
      },
      emailSubject: 'Recordatorio de cita',
      emailBody:
        insureSelected.isMain === 0
          ? `<p>Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir! </p>`
          : `<p> Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir!</p>`,
      fileBase64: '',
      show: false,
    });
  };

  const handleKeyDownReminder = event => {
    const { inputValue, value } = stateInputReminder;
    if (inputValue) {
      let tempArray = value;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          tempArray.push(createOptionReminder(event.target.value));
          setStateInputReminder({ inputValue: '', value: tempArray });
          break;
        default:
      }
    }
  };

  const handleInputChangeReminder = inputValue => {
    if (typeof inputValue == 'string') {
      setStateInputReminder({ ...stateInputReminder, inputValue: inputValue });
      let tempArray = [];
      stateInput.value.forEach(elem => {
        tempArray.push(elem.label);
      });
    } else if (Array.isArray(inputValue)) {
      setStateInputReminder({ ...stateInputReminder, value: inputValue });
    }
  };

  const createReminder = () => {
    let tempValues = [];
    stateInputReminder?.value.forEach(e => {
      tempValues.push(e.value);
    });
    setModalReminder({ ...modalReminder, cluEmail: tempValues });

    let validations = true;

    if (isEmptyOrUndefined(modalReminder.emailSubject)) {
      validations = false;
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: "El campo 'Asunto' es obligatorio",
        showCancelButton: false,
      });
    }

    let emailValidation;

    if (tempValues?.length > 0) {
      tempValues.forEach(e => {
        if (validateEmail(e) === true) {
          emailValidation = true;
        } else {
          return customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'Valide su correo',
            showCancelButton: false,
          });
        }
      });
    } else {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: "El campo 'Para' es obligatorio",
        showCancelButton: false,
      });
    }
    let appId = [];
    appId.push(modalReminder.appId);
    if (validations && emailValidation) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se enviará el recordatorio de cita`,
        confirmButtonText: 'Si, Continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          const appIds = [];
          appId?.forEach(c => {
            c?.forEach(f => {
              appIds?.push(f);
            });
          });
          createReminderMethod({
            url: '/medical/appointment/paramsEmail/',
            method: 'POST',
            body: {
              appIds,
              cluEmail: tempValues,
              emailSubject: modalReminder.emailSubject,
              emailBody: modalReminder.emailBody,
              fileBase64: modalReminder.fileBase64,
            },
            token: token,
            doAfterSuccess: info => {
              closeModalReminder();
              return customSwaltAlertAsistencial({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: info?.message,
                showCancelButton: false,
              });
            },
          });
        }
      });
    }
  };

  const onChangeSelectOfService = ({ serviceId, serviceName, sorId }) => {
    setFilters({
      service: { label: serviceName, value: serviceId },
      doctor: DEFAULT_OPTION,
      location: DEFAULT_OPTION,
      site: DEFAULT_OPTION,
      sorId,
    });

    void getListLocations({
      token,
      url: '/medical/appointment/locationService/',
      objFilters: { modality, idAccount, srvId: serviceId },
    });

    setSelectedDate(SELECTED_DATE_INITAL_STATE);
    clearSchedules();
    clearSchedulesMonth();
  };

  const onChangeSelectOfLocation = ({ locationId, locationName }) => {
    setFilters(state => ({
      ...state,
      location: { label: locationName, value: locationId },
      doctor: DEFAULT_OPTION,
      site: DEFAULT_OPTION,
    }));

    void getListDoctors({
      token,
      url: '/medical/appointment/medical-by-contract-and-location/',
      objFilters: {
        idAccount,
        popId,
        contractId,
        srvId: filters.service.value,
        city: locationId,
      },
    });

    setSelectedDate(SELECTED_DATE_INITAL_STATE);
    clearSchedules();
    clearSchedulesMonth();
  };

  const onChangeSelectOfSite = ({ siteId, siteName }) => {
    const formatSite = { label: siteName, value: siteId };

    setFilters(state => ({
      ...state,
      site: formatSite,
      doctor: DEFAULT_OPTION,
    }));

    setSelectedDate(SELECTED_DATE_INITAL_STATE);
    clearSchedules();
    clearSchedulesMonth();
  };

  const onChangeSelectOfDoctor = ({ doctorId, doctorName }) => {
    const formatDoctor = { label: doctorName, value: doctorId };

    setFilters(state => ({
      ...state,
      doctor: formatDoctor,
    }));
  };

  const getMonthDates = (monthYear, monthNumber) => {
    void getScheduleMonth({
      token,
      url: '/medical/appointment/availability/',
      objFilters: {
        modality,
        eaccount: idAccount,
        attentionGroup: attGroupId,
        idContractcorporateClient: contractId,
        viewMode: 'month',
        monthYear,
        monthNumber,
        idCorporateClient: corporateClientId,
        idPopulation: populationId,
        idService: filters.service.value,
        city: filters.location.value,
        idDoctor: filters.doctor.value,
        site: filters.site.value,
        days: 3,
      },
      doAfterException: res => {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res?.message,
          showCancelButton: false,
        });
      },
    });
  };

  const onSearchSchedules = () => {
    setEnabledRequestExtraQuote(false);
    setExtraQuotaSpacesAvailable(true);

    if (!filters.service.value || !filters.location.value || !filters.site.value) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar un servicio, ubicación y sede para encontrar las agendas.',
        showCancelButton: false,
      });
    }

    void getScheduleDoctor({
      token,
      url: '/medical/appointment/availability/',
      objFilters: {
        modality,
        eaccount: idAccount,
        attentionGroup: attGroupId,
        idContractcorporateClient: contractId,
        idCorporateClient: corporateClientId,
        idPopulation: populationId,
        idService: filters.service.value,
        city: filters.location.value,
        idDoctor: filters.doctor.value,
        site: filters.site.value,
        days: 3,
      },
      doAfterException: res => {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res?.message,
          showCancelButton: false,
        });
      },
      succesAction: res => {
        getMonthDates(
          moment(res.proximateDates?.[0]).year(),
          moment(res.proximateDates?.[0]).month() + 1,
        );

        setSelectedDate({
          ...SELECTED_DATE_INITAL_STATE,
          currentDate: res.proximateDates?.[0],
        });
        if (!res?.results?.length) {
          clearSchedules();
          clearSchedulesMonth();

          return customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'No hay agendas disponibles',
            showCancelButton: false,
          });
        }
      },
    });
  };
  const onToggleDoctor = ({ idSchedule, schedules, doctor }) => {
    setSelectedDate(state => ({ ...state, schedules, scheduleId: idSchedule, doctor }));
    setSelectedTime({ startTime: '', endTime: '' });
  };

  //por verificar
  const onToggleHourSchedule = ({ hourStart, hourEnd }) => {
    setSelectedTime({
      startTime: hourStart,
      endTime: hourEnd,
    });
  };

  const onCheckExtraQuotaAvailability = ({
    newDate,
    requestExtraQuote,
    extraQuotaSpacesAvailableValue,
  }) => {
    const fomattedDate = moment(newDate).format('YYYY-MM-DD');

    void checkExtraQuotaAvailability({
      token,
      url: '/medical/appointment/availability/',
      objFilters: {
        modality,
        eaccount: idAccount,
        attentionGroup: attGroupId,
        idContractcorporateClient: contractId,
        idCorporateClient: corporateClientId,
        idPopulation: populationId,
        idService: filters?.service?.value,
        additionalQuotaMode: requestExtraQuote,
        city: filters?.location?.value,
        idDoctor: filters?.doctor?.value,
        site: filters?.site?.value,
        dateSearch: fomattedDate,
        days: 3,
      },
      succesAction: res => {
        if (extraQuotaSpacesAvailableValue && requestExtraQuote === 1) {
          const value = res.results.some(item => item.availableSpaces > 0);
          setExtraQuotaSpacesAvailable(!value);
        } else if (requestExtraQuote === undefined) {
          setExtraQuotaSpacesAvailable(true);
        }
      },
    });
  };

  const onChangeDatePicker = ({ newDate, requestExtraQuote }) => {
    const fomattedDate = moment(newDate).format('YYYY-MM-DD');
    setSelectedDate({
      currentDate: fomattedDate,
      schedules: [],
      scheduleId: null,
      doctor: null,
    });

    void getScheduleDoctor({
      token,
      url: '/medical/appointment/availability/',
      objFilters: {
        modality,
        eaccount: idAccount,
        attentionGroup: attGroupId,
        idContractcorporateClient: contractId,
        idCorporateClient: corporateClientId,
        idPopulation: populationId,
        idService: filters?.service?.value,
        additionalQuotaMode: requestExtraQuote,
        city: filters?.location?.value,
        idDoctor: filters?.doctor?.value,
        site: filters?.site?.value,
        dateSearch: fomattedDate,
        days: 3,
      },
      succesAction: res => {
        if (!res?.results?.length) {
          return customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'No hay agendas disponibles',
            showCancelButton: false,
          });
        }
      },
      doAfterException: res => {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res?.message,
          showCancelButton: false,
        });
      },
    });
  };

  const onCreateAppointment = async () => {
    if (!appointments.length) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe agendar al menos una agenda.',
        showCancelButton: false,
      });
      return;
    }
    const body = appointments.map(app => {
      return {
        sorId: filters.sorId ?? '',
        affiliationTypeId: detailPatient?.results?.affiliationTypeId,
        modality,
        idPatient: detailPatient?.results?.id,
        createdBy: idUser,
        idCorporateClient: corporateClientId,
        idPopulation: popId,
        rv: wsValidated === 0 ? 'False' : 'True',
        idCompanyType: companyType,
        eaccount: idAccount,
        origin: 'on_site',
        status: 'pending_payment',
        idSch: app.scheduleId,
        idDoctor: app.doctorId,
        idDoctorContract: app.idDoctorContract,
        idContractCorporateClient: app.idContractCorporateClient,
        idService: app.serviceId,
        appObservations: observations,
        category: crtName,
        appDatetime: `${app.appointmentDate} ${app.appointmentStart}`,
        appEndDatetime: `${app.appointmentDate} ${app.appointmentEnd}`,
      };
    });

    const firstAppointment = appointments[0];

    if (selectedTime.startTime || selectedDate.doctor) {
      const answer = await customSwaltAlertAsistencial({
        icon: 'warning',
        title: '¿Está seguro?',
        text: 'Tiene una cita en proceso de agendamiento, ¿desea continuar con el registro de citas?',
        confirmButtonText: 'Sí, continuar',
        showCancelButton: true,
      });
      if (answer.isDismissed || answer.isDenied) return;
    }

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text:
        appointments?.length === 1
          ? `Se agendará una cita al paciente ${fullNamePatient} el día ${firstAppointment.appointmentDate} a las ${firstAppointment.appointmentStart}`
          : `Se agendarán (${appointments.length}) citas para el paciente ${fullNamePatient}`,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        createAppointmentMethod({
          url: '/medical/appointment/createAppointments/',
          method: 'POST',
          body: { apps: body },
          token: token,
          doAfterSuccess: info => {
            postTicketFunction('appointment_registration', info?.results?.appIds);

            getTicket({
              url: '/medical/appointment/concecutive/',
              objFilters: { idConsec: 23 },
              token: token,
              doAfterSuccess: res => {
                setTicketValue(res.results);
              },
            });
          },
          succesAction: info => {
            getCiteAppointment({
              url: '/medical/appointment/',
              objFilters: { appId: info?.results?.appIds[0] },
              token: token,
              doAfterSuccess: data => {
                if (
                  data?.results?.ctName?.toLowerCase() === 'Prepagada'.toLowerCase() &&
                  data?.results?.modality?.toLowerCase() === 'tmed'.toLowerCase()
                ) {
                  createSmartQueMethod({
                    url: '/medical/appointment/smartQue/',
                    method: 'POST',
                    body: { idApp: info?.results?.appIds[0] },
                    noAlert: true,
                    token: token,
                  });
                }
              },
            });

            customSwaltAlertAsistencial({
              icon: 'success',
              title: 'Cita registrada',
              text: info.message,
              confirmButtonText: 'Ver Recordatorio',
            }).then(result => {
              if (result.isConfirmed) {
                setModalReminder({ ...modalReminder, show: true, appId: info?.results?.appIds });
              }
            });

            setFilters({
              service: DEFAULT_OPTION,
              location: DEFAULT_OPTION,
              site: DEFAULT_OPTION,
              doctor: DEFAULT_OPTION,
              sorId: null,
            });
            setSelectedDate(SELECTED_DATE_INITAL_STATE);
            setSelectedTime({ endTime: '', startTime: '' });
            setAppointments([]);
            clearSchedules();
            clearSchedulesMonth();
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message,
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const sendRequestQuota = () => {
    const appointment = appointments.find(app => app.isExtraQuota);

    if (!extraQuoteModal.reason) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Asegúrese de llenar el motivo.',
        showCancelButton: false,
      });
    }

    requestQuotaMethod({
      url: '/medical/appointment/requestExtraQuota/',
      method: 'POST',
      token: token,
      body: {
        cluId: detailPatient?.results?.id,
        srvId: appointment.serviceId,
        popId: populationId,
        appDateTime: `${appointment.appointmentDate} ${appointment.appointmentStart}`,
        appEndDatetime: `${appointment.appointmentDate} ${appointment.appointmentEnd}`,
        schId: appointment.scheduleId,
        doctorId: appointment.doctorId,
        cclientId: corporateClientId,
        contractId: contractId,
        userId: idUser,
        crtId: crtId,
        crtName: crtName,
        comments: extraQuoteModal.reason,
      },
      succesAction: info => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Cupo adicional solicitado',
          text: info.message,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
        setFilters({
          service: DEFAULT_OPTION,
          location: DEFAULT_OPTION,
          site: DEFAULT_OPTION,
          doctor: DEFAULT_OPTION,
          sorId: null,
        });
        setExtraQuoteModal({ open: false, reason: '' });
        setEnabledRequestExtraQuote(false);
        setSelectedDate(SELECTED_DATE_INITAL_STATE);
        setSelectedTime({ endTime: '', startTime: '' });
        setAppointments([]);
        clearSchedules();
        clearSchedulesMonth();
      },
      doAfterException: error => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error.message,
          showCancelButton: false,
        });
      },
    });
  };

  const onAddNewAppointment = () => {
    const isRepeated = appointments.some(
      app =>
        app.scheduleId === selectedDate.scheduleId &&
        app.appointmentStart === selectedTime.startTime,
    );

    if (!selectedDate.doctor) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar un doctor para la cita.',
        showCancelButton: false,
      });
      return;
    }

    if (!selectedTime.startTime) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe ingresar la hora de la cita.',
        showCancelButton: false,
      });
      return;
    }

    if (isRepeated) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Asegúrese de no repetir las agendas de las citas.',
        showCancelButton: false,
      });
      return;
    }
    const schedule = scheduleOptions.find(item => item?.idDoctor === selectedDate.doctor?.doctorId);

    setAppointments(state => [
      ...state,
      {
        key: generateId(),
        serviceId: filters.service.value,
        serviceName: filters.service.label,
        appointmentDate: selectedDate.currentDate,
        appointmentStart: selectedTime.startTime,
        appointmentEnd: selectedTime.endTime,
        scheduleId: selectedDate.scheduleId,
        siteName: filters.site.label,
        doctorName: selectedDate.doctor?.doctorName,
        doctorId: selectedDate.doctor?.doctorId,
        idDoctorContract: schedule?.idDoctorContract,
        idContractCorporateClient: schedule?.idContractCorporateClient,
        isExtraQuota: enabledRequestExtraQuote,
      },
    ]);
    setSelectedDate(SELECTED_DATE_INITAL_STATE);
    setSelectedTime({ startTime: '', endTime: '' });
    setFilters({
      service: DEFAULT_OPTION,
      location: DEFAULT_OPTION,
      site: DEFAULT_OPTION,
      doctor: DEFAULT_OPTION,
      sorId: null,
    });
    clearSchedules();
    clearSchedulesMonth();
  };

  const onDeleteAppointment = appointmentKey => {
    const newAppointments = appointments.filter(app => app.key !== appointmentKey);
    setAppointments(newAppointments);
  };

  const renderDay = (day, date) => {
    const dateObject = new Date(date);
    const today = moment();
    const listOfDaysOfMonth = listSchedulesMonthResult?.results;
    const formattedDate = moment(dateObject).format('YYYY-MM-DD');

    const currentDayData = listOfDaysOfMonth?.filter(
      currentDate => currentDate?.date === formattedDate,
    );

    let content = '';
    if (currentDayData?.length) {
      if (currentDayData?.[0]?.spaces === 0 && currentDayData?.[0]?.scheduled === 0) {
        content = 'Bloqueado';
      }

      if (currentDayData?.[0]?.spaces > 0) {
        content = `${currentDayData?.[0]?.spaces} cupos disponibles`;
      }

      if (
        currentDayData?.[0]?.spaces === 0 &&
        currentDayData?.[0]?.scheduled > 0 &&
        moment(formattedDate).isAfter(today)
      ) {
        content = `Sin cupos`;
      }

      return (
        <Tooltip title={content} placement='top' arrow>
          <div
            className={` ${content === 'Sin cupos' ? styles.textDanger : ''}  `}
            style={{
              display: 'inline-block',
              cursor: 'pointer',
            }}
          >
            {day}
          </div>
        </Tooltip>
      );
    }

    return <span className={` ${content === 'Sin cupos' ? styles.textDanger : ''}  `}>{day}</span>;
  };

  return (
    <div style={{ overflow: 'auto' }}>
      {(loaderListLocations ||
        loaderListServices ||
        loaderListDoctors ||
        loaderCreateAppointment ||
        loaderTicket ||
        loaderCiteAppointment ||
        loaderPostSmartQue ||
        createReminderLoader ||
        requestQuotaLoader ||
        loaderScheduleMonth ||
        loaderScheduleDoctor) &&
        loader}

      <Box
        style={{ padding: '24px 24px 28px 24px', position: 'relative', marginTop: '20px' }}
        className={`${styles.app_container_calendar}`}
      >
        {/*component filters appointment */}
        <SearchAppointment
          listDoctors={doctorOptions}
          listServices={serviceOptions}
          listLocations={locationOptions}
          service={filters.service}
          location={filters.location}
          site={filters.site}
          doctor={filters.doctor}
          order={order}
          onSearchSchedules={() => onSearchSchedules()}
          onChangeSelectOfService={({ serviceName, serviceId, sorId }) => {
            onChangeSelectOfService({ serviceId, serviceName, sorId });
          }}
          onChangeSelectOfLocation={({ locationName, locationId }) => {
            onChangeSelectOfLocation({ locationId, locationName });
          }}
          onChangeSelectOfSite={({ siteName, siteId }) => {
            onChangeSelectOfSite({ siteId, siteName });
          }}
          onChangeSelectOfDoctor={({ doctorName, doctorId }) => {
            onChangeSelectOfDoctor({ doctorId, doctorName });
          }}
        />

        <Box mt={2} className={`${styles.app_line_search}`} />

        <Box
          mt={2}
          display={'grid'}
          gridTemplateColumns={'260px 1fr 260px'}
          alignItems={'flex-start'}
          columnGap={3}
          height={300}
        >
          {scheduleOptions.length ? (
            <div>
              <div id='new-style-react-date'>
                <DatePicker
                  inline
                  key={1}
                  locale={es}
                  minDate={new Date()}
                  selected={convertLocalDate(selectedDate.currentDate)}
                  onChange={newDate => {
                    onChangeDatePicker({ newDate });
                    setEnabledRequestExtraQuote(false);
                  }}
                  onMonthChange={date => {
                    const formattedDate = moment(date).format('YYYY-MM-DD');
                    getMonthDates(moment(formattedDate).year(), moment(formattedDate).month() + 1);
                  }}
                  highlightDates={proximateDates}
                  renderDayContents={renderDay}
                />
              </div>
              <button
                className={`${tableStyles.button_assign_appointment} ${enabledRequestExtraQuote ? tableStyles.inverse : ''}`}
                onClick={() => {
                  const newValue = !enabledRequestExtraQuote;
                  setEnabledRequestExtraQuote(newValue);
                  onChangeDatePicker({
                    newDate: selectedDate.currentDate,
                    requestExtraQuote: newValue ? 1 : undefined,
                  });
                }}
                disabled={extraQuotaSpacesAvailable}
              >
                {enabledRequestExtraQuote ? 'Cancelar cupo adicional' : 'Solicitar cupo adicional'}
              </button>
            </div>
          ) : (
            <div />
          )}

          <SearchAppointmentCarousel
            key={appointments.length}
            currentDate={selectedDate.currentDate}
            schedules={selectedDate.schedules}
            onToggleHourSchedule={({ idSchedule, hourStart, hourEnd, date, time }) => {
              onToggleHourSchedule({
                date,
                time,
                hourStart,
                hourEnd,
                idSchedule,
              });
            }}
            selectedHours={appointments.map(app => app.appointmentStart)}
            selectedSchedules={appointments.map(app => app.scheduleId)}
          />

          <div className='h-100' style={{ overflowY: 'auto' }}>
            {scheduleOptions?.map(schedule => (
              <SearchCardDoctor
                active={schedule?.idSchedule === selectedDate.scheduleId}
                key={schedule?.idSchedule}
                scheduleName={schedule?.scheduleName}
                doctorId={schedule?.idDoctor}
                doctorName={schedule?.doctor}
                doctorImage={schedule?.image}
                onToggleDoctor={doctor => {
                  onToggleDoctor({
                    idSchedule: schedule?.idSchedule,
                    schedules: schedule?.schedule,
                    doctor,
                  });
                }}
                onSearchExtraQuota={() => {
                  onCheckExtraQuotaAvailability({
                    newDate: selectedDate.currentDate,
                    requestExtraQuote: 1,
                    extraQuotaSpacesAvailableValue: true,
                  });
                }}
              />
            ))}
          </div>
        </Box>
        {!extraQuoteModal?.open && scheduleOptions.length > 0 && (
          <div className='d-flex justify-content-end w-100 mt-4'>
            <div
              className={`${tableStyles.ordOrangeText} d-inline-flex align-items-center pointer`}
              style={{ marginTop: '15px' }}
              onClick={onAddNewAppointment}
            >
              <AddCircleOutlineRounded fontSize='small' color='#f39682' />
              <div className={`${tableStyles.f14} mx-2 fw-bold`} style={{ marginTop: '2px' }}>
                Agregar cita
              </div>
            </div>
          </div>
        )}
      </Box>

      <OrdTable
        headers={APPOINTMENT_HEADER}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
      >
        {appointments.length > 0 ? (
          appointments.map(appointment => (
            <tr key={appointment.key}>
              <td className='px-2 text-start'>{appointment.serviceName}</td>
              <td className='px-2 text-start'>
                {moment(appointment.appointmentDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              </td>
              <td className='px-2 text-start'>{appointment.appointmentStart}</td>
              <td className='px-2 text-start'>{appointment.siteName}</td>
              <td className='px-2 text-start'>{appointment?.doctorName}</td>
              <td className='px-2 text-end'>
                <DeleteOutline
                  fontSize='small'
                  className='pointer'
                  onClick={() => onDeleteAppointment(appointment.key)}
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={6} className='text-center'>
              No hay citas agendadas
            </td>
          </tr>
        )}
      </OrdTable>

      <Form.Group className='mb-3' controlId='fName'>
        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
          <b className={` px-1`}>Anotaciones</b>
        </Form.Label>
        <Form.Control
          as='textarea'
          rows={2}
          className='text-secondary'
          type='text'
          placeholder='Escribe...'
          value={observations}
          onChange={({ target }) => setObservations(target.value)}
        />
      </Form.Group>

      <div className='my-4'>
        <div className='d-flex gap-2 justify-content-end align-items-end align-self-end mb-2'>
          <button
            onClick={goBackFunction}
            className={`${tableStyles.ordBtnSecondary} p-2`}
            style={{ height: '35px' }}
          >
            Cancelar
          </button>
          <button
            onClick={() => {
              enabledRequestExtraQuote
                ? setExtraQuoteModal({ open: true, reason: '' })
                : onCreateAppointment();
            }}
            className={`${tableStyles.ordBtnPrimary} p-2`}
            style={{ height: '35px' }}
          >
            {enabledRequestExtraQuote ? 'Solicitar' : 'Registrar'}
          </button>
        </div>
      </div>

      <OrdModal
        title='Motivo'
        show={extraQuoteModal.open}
        btnYesName='Aceptar'
        btnNoName='Cancelar'
        size='200'
        onHide={() => setExtraQuoteModal({ open: false, reason: '' })}
        btnYesEvent={() => sendRequestQuota()}
        btnNoEvent={() => setExtraQuoteModal({ open: false, reason: '' })}
      >
        <Form.Group className=' text-start' controlId=''>
          <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              &nbsp;Motivo <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
            </span>
          </Form.Label>
          <textarea
            placeholder={'Escribir...'}
            style={{ height: '5rem' }}
            className={`text-secondary ord-roundInput ord-roundInputActive w-100`}
            value={extraQuoteModal.reason}
            onChange={({ target }) => {
              setExtraQuoteModal(state => ({ ...state, reason: target.value }));
            }}
          />
        </Form.Group>
      </OrdModal>

      <RenderPdf
        isOpenPdf={modalReminder?.show}
        onHidePdf={closeModalReminder}
        appId={String(modalReminder?.appId)}
        setModalReminder={setModalReminder}
        closeModalReminder={closeModalReminder}
        stateInputReminder={stateInputReminder}
        handleInputChangeReminder={handleInputChangeReminder}
        handleKeyDownReminder={handleKeyDownReminder}
        modalReminder={modalReminder}
        createReminder={createReminder}
      />
    </div>
  );
};
