import { Drawer } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import alertCircle from '../../assets/img/icons/alert-circle-auxiliary-red.svg';
import alertIconOrd from '../../assets/img/icons/alertIconOrd.svg';
import arrow from '../../assets/img/icons/arrowTable.svg';
import closeIcon from '../../assets/img/icons/close.svg';
import iconMessage from '../../assets/img/icons/icon_message.svg';
import iconTrans from '../../assets/img/icons/trashNotes.svg';
import styles from '../../components/Layouts/tableStyle.module.scss';
import {
  formatteDocument,
  formatToRcSelect,
  getAge,
  loader,
  message,
  swalConfirm,
} from '../../helpers';

import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import warningGray from '../../assets/img/icons/warningGray.svg';
import { Risk } from '../../components/Risk/Risk';
const PatientList = () => {
  const history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idUser = store.loginReducer.user_data.id;
  const fullNameUser =
    store.loginReducer.user_data.first_name + ' ' + store.loginReducer.user_data.first_surname;
  const [trigger, setTrigger] = useState(1);
  const [comments, setComments] = useState({
    userId: idUser,
    entity: 'userClient',
    comment: '',
    id: '',
  });

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    filterPatient: '',
  });

  const [patient, setPatient] = useState({
    status: 'enabled',
  });

  const [modalRisk, setModalRisk] = useState({
    show: false,
    title: 'Importancias',
    listRisk: [],
    patient: undefined,
    type: 'administrative',
  });

  const [services, setServices] = useState({
    data: {},
    showDrawer: false,
    showImportance: false,
    header: [
      {
        title: 'Paciente',
        className: 'col-3 text-start px-3',
      },
      {
        title: 'No. Identificación',
        className: 'col-2 text-start px-3',
      },
      {
        title: 'Edad',
        className: 'col-1 text-start',
      },
      {
        title: 'EPS',
        className: 'col-3 text-start',
      },
      {
        title: 'Última atención',
        className: 'col-2 text-center',
      },
      {
        title: 'Notas',
        className: 'col-1 text-start',
      },
      {
        title: 'Alerta',
        className: 'col-2 text-start',
      },
      {
        title: <>&nbsp;</>,
        className: 'col-2 text-end px-3',
      },
    ],
  });

  const { results: listPatient, load: loadListPatient, trigger: getListPatient } = useGetMethod();

  const {
    results: generalComments,
    load: loadGeneralComments,
    trigger: getGeneralComments,
  } = useGetMethod();

  const { results: importancies, trigger: getImportancies } = useGetMethod();
  const { trigger: getDetailPatient } = useGetMethod();
  const { load: loadCreateComments, trigger: sendComment } = usePostMethod();
  const { load: loadDeleteComments, trigger: deleteComment } = usePostMethod();
  const { load: loadCreateMarkPatient, trigger: sendMarkPatient } = usePostMethod();

  useEffect(() => {
    getListPatient({
      url: '/medical/patient/listPatient/',
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    getImportancies({
      url: '/medical/markups/',
      objFilters: { status: 'enabled' },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIdComment = id => {
    const filterComments = {
      id: id,
      entity: 'userClient',
    };
    getGeneralComments({
      url: '/medical/comments/',
      objFilters: filterComments,
      token: token,
    });
    setComments({ ...comments, id: id });
    setServices({ ...services, showDrawer: true });
  };

  const getIdPatient = id => {
    history.push({
      pathname: '/admision/DetallePacientes/',
      state: {
        idPatient: id,
      },
    });
  };

  const getIdPatientList = id => {
    getDetailPatient({
      url: '/medical/patient/',
      objFilters: { id: id, status: 'enabled' },
      token: token,
    });
    setPatient({ id: id });
  };

  const getIdNotes = id => {
    deleteComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'DELETE',
      body: { id: id },
      doAfterSuccess: () => {
        const filterComments = {
          id: comments.id,
          entity: 'userClient',
        };
        getGeneralComments({
          url: '/medical/comments/',
          objFilters: filterComments,
          token: token,
        });
        getListPatient({
          url: '/medical/patient/listPatient/',
          objFilters: filters,
          token: token,
        });
        setServices({ ...services, showDrawer: false });
      },
    });
  };

  const handleFilterPatient = e => {
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
    e.preventDefault();
  };

  const onSubmitComment = e => {
    e.preventDefault();
    sendComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'POST',
      body: comments,
      doAfterSuccess: () => {
        const filterComments = {
          id: comments.id,
          entity: 'userClient',
        };
        getGeneralComments({
          url: '/medical/comments/',
          objFilters: filterComments,
          token: token,
        });
        setComments({ ...comments, comment: '' });
        getListPatient({
          url: '/medical/patient/listPatient/',
          objFilters: filters,
          token: token,
        });
      },
    });
  };

  const OnSubmitMarkPatient = () => {
    sendMarkPatient({
      url: '/medical/patient/',
      token: token,
      method: 'PUT',
      body: patient,
      doAfterSuccess: () => {
        getDetailPatient({
          url: '/medical/patient/',
          objFilters: patient,
          token: token,
        });
        getListPatient({
          url: '/medical/patient/listPatient/',
          objFilters: filters,
          token: token,
        });
        setServices({
          ...services,
          showImportance: false,
        });
      },
    });
  };

  const renderToolImportance = text => (
    <Tooltip>
      <span className={`${styles.darkerGrayText} ${styles.accordinElipsis} `}>{text}</span>
    </Tooltip>
  );

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-start px-3'>
              {item.first_name} {item?.other_names} {item.last_name} {item?.second_surname}
            </td>
            <td className='text-start px-3'>{formatteDocument(item.document).format}</td>
            <td className='text-start px-2'>
              {!isNaN(getAge(item.birth_date)) ? getAge(item.birth_date) : '-'}
            </td>
            <td className='text-start'>{item.copyName || '-'}</td>
            <td className='text-center'>{item.last_attention}</td>
            <td className='text-center px-2'>
              <div
                className={
                  item.notes_num === 0
                    ? styles.app_container_dialog_gray
                    : styles.app_container_dialog_blue
                }
                onClick={() => {
                  getIdComment(item.id);
                }}
              >
                <span className={styles.app_text_dialog}>
                  {item.notes_num === 0 ? (
                    <span style={{ color: '#6e6f7c' }}>0</span>
                  ) : (
                    item.notes_num
                  )}
                </span>
              </div>
            </td>
            <td className='text-center'>
              {item.alert === '' ? (
                <div>
                  <img
                    alt={'icon'}
                    width={'22px'}
                    src={alertIconOrd}
                    className={'cursorPointer'}
                    onClick={() => {
                      getIdPatientList(item.id);
                      setServices({ ...services, showImportance: true });
                      setPatient({ ...patient, cum_id: item?.alertId, id: item?.id });
                    }}
                  />
                </div>
              ) : (
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  className={styles.app_container_tool_websc}
                  overlay={renderToolImportance(
                    item?.risk['administrative']?.length > 0 ? (
                      item?.risk['administrative']?.map(risk => (
                        <ul
                          key={risk.id}
                          style={{ textAlign: 'start', margin: 0, padding: 0, listStyle: 'none' }}
                        >
                          <li>
                            <b>-</b>&nbsp;{risk?.description}
                          </li>
                        </ul>
                      ))
                    ) : (
                      <div className={`cursorPointer`}>No hay alertas</div>
                    ),
                  )}
                >
                  <span>
                    <img
                      alt={'icon'}
                      width={'22px'}
                      src={item?.risk['administrative']?.length > 0 ? alertCircle : warningGray}
                      className={`cursorPointer`}
                      onClick={() => {
                        setModalRisk({
                          ...modalRisk,
                          type: 'administrative',
                          title: 'Tipos de importancia',
                          listRisk: item?.risk,
                          show: true,
                          patient: item,
                          defaultValueSelect: { label: item.alert, value: item.alertId },
                        });
                        getIdPatientList(item.id);
                        setServices({ ...services, showImportance: true });
                        setPatient({ ...patient, cum_id: item?.alertId, id: item?.id });
                      }}
                    />
                  </span>
                </OverlayTrigger>
              )}
            </td>
            <td className='text-center px-3'>
              <img
                className={'cursorPointer'}
                alt={'arrow'}
                src={arrow}
                width={12}
                onClick={() => {
                  getIdPatient(item.id);
                }}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatDataComments = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <>
            <div key={index} style={{ paddingLeft: '16px' }}>
              <div className={styles.app_container_tras_notes}>
                <span className={styles.app_name_drawer}>{item.userName}</span>
                <img
                  src={iconTrans}
                  width={15}
                  alt='tras-notes'
                  className='cursorPointer'
                  onClick={() => {
                    setServices({ ...services, showDrawer: true });
                    swalConfirm({
                      title: `<span class=${styles.ordClearBlueText} >¿Desea eliminar la nota?</span>`,
                      text: ` `,
                      confirmButtonText: 'Si, continuar',
                      doAfterConfirm: () => {
                        getIdNotes(item.id);
                        message('success', 'Nota eliminada');
                      },
                    });
                  }}
                />
              </div>
              <p className={styles.app_description_drawer}>{item.comment}</p>
              <div className={styles.app_container_date_drawer}>
                <span className={styles.app_date_drawer}>{item.date}</span>
              </div>
            </div>
            <div className={styles.app_border_drawer} />
          </>,
        );
      });
    }
    return tempList;
  };

  const formatedImportances = formatToRcSelect(importancies.results, 'id', 'description');

  const handleCloseModalRisk = () => {
    setModalRisk({
      ...modalRisk,
      show: false,
      type: 'administrative',
      defaultValueSelect: undefined,
      listRisk: [],
      patient: undefined,
    });
  };

  return (
    <>
      {/* /* ------------------------------ load sections ----------------------------- */}
      {(loadListPatient || loadDeleteComments || loadCreateMarkPatient) && loader}

      <Risk
        {...modalRisk}
        key={1}
        idAccount={idUser}
        listRisk={modalRisk?.listRisk}
        onHide={handleCloseModalRisk}
        btnNoEvent={handleCloseModalRisk}
        defaultValueSelect={modalRisk?.defaultValueSelect}
        onAdd={res => setModalRisk({ ...modalRisk, listRisk: { [modalRisk.type]: res } })}
        onRemove={res => setModalRisk({ ...modalRisk, listRisk: { [modalRisk.type]: res } })}
        doAfterSuccess={() => {
          setTrigger(state => state + 1);
          handleCloseModalRisk();
        }}
        onSubmitMarkPatient={OnSubmitMarkPatient}
        formatedImportances={formatedImportances}
      />

      {/* <OrdModal
        title='Tipo de importancia'
        show={services.showImportance}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={'200'}
        onHide={() => setServices({ ...services, showImportance: false })}
        btnYesEvent={OnSubmitMarkPatient}
        btnNoEvent={() => setServices({ ...services, showImportance: false })}
      >
        <div>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={formatedImportances}
            className='text-secondary'
            value={formatedImportances.filter(x => x.value === patient?.cum_id)}
            placeholder={'Seleccionar...'}
            onChange={e => setPatient({ ...patient, cum_id: e.value })}
            styles={appCustomSelectNew}
          />
        </div>

      </OrdModal> */}
      <Drawer
        anchor={'right'}
        open={services.showDrawer}
        style={{ zIndex: 900 }}
        onClose={() => setServices({ ...services, showDrawer: false })}
      >
        <div className={styles.app_drawer}>
          <div className={styles.app_container_close}>
            <img
              src={closeIcon}
              width={25}
              className={'cursorPointer'}
              alt='closeIcon'
              onClick={() => {
                setComments({ ...comments, id: '' });
                setServices({ ...services, showDrawer: false });
              }}
            />
          </div>
          <div className={styles.app_container_drawer}>
            <span className={styles.app_title_drawer}>Notas</span>
            {loadGeneralComments && loader}
            {generalComments.rowTotal === 0 ? (
              <div className={styles.app_container_not_notes}>
                <span className={styles.app_title_not_notes}>No hay notas aún</span>
              </div>
            ) : (
              <div className={styles.app_container_content_drawer}>
                {formatDataComments(generalComments.results)}
              </div>
            )}

            <div className={styles.app_container_form_drawer}>
              {loadCreateComments && loader}
              <Form.Group style={{ marginBottom: '10px' }}>
                <Form.Label className={styles.app_title_input}>
                  <span>{fullNameUser}</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  maxLength='170'
                  value={comments.comment}
                  onChange={e => setComments({ ...comments, comment: e.target.value })}
                  placeholder='Escribir...'
                />
              </Form.Group>
              <div className={styles.app_container_date_drawer}>
                <img
                  src={iconMessage}
                  className={'cursorPointer'}
                  alt='iconMessage'
                  onClick={onSubmitComment}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <div className='container-fluid'>
        <OrdGenericTemplate title='Listado de pacientes'>
          <div className={styles.app_mr_100}>
            <Row>
              <Col md={5} xs={5}>
                <Row>
                  <Col xs={6}>
                    <Form.Group className='' controlId='fName'>
                      <Form.Label className={`m-0 ${styles.ordDarkBlueText} `}>
                        <b className='text-start'>&nbsp;Fecha desde</b>
                      </Form.Label>
                      <Form.Control
                        className={`ord-roundInput ${styles.inputDateServiceReports}`}
                        type='date'
                        placeholder=''
                        value={filters.endDate}
                        onChange={e => {
                          setFilters({ ...filters, endDate: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className='' controlId='fName'>
                      <Form.Label className={`m-0 ${styles.ordDarkBlueText} `}>
                        <b className='text-start'>&nbsp;Hasta</b>
                      </Form.Label>
                      <Form.Control
                        className={`ord-roundInput ${styles.inputDateServiceReports}`}
                        type='date'
                        placeholder=''
                        value={filters.endDate}
                        onChange={e => {
                          setFilters({ ...filters, endDate: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col xs={5}>
                <form onSubmit={e => handleFilterPatient(e)}>
                  <Form.Group className='mb-3 text-start' controlId='search'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}></Form.Label>
                    <div className={`d-flex ${styles.ordGrayInputSearch}`}>
                      <Form.Control
                        className={`ord-roundInput-search`}
                        type='text'
                        onChange={e => setFilters({ ...filters, search: e.target.value })}
                        placeholder='Escribe aquí para buscar'
                      />
                      <img alt='buscar' src={ordBlueSearch}></img>
                    </div>
                  </Form.Group>
                </form>
              </Col>
            </Row>

            <OrdTable
              hasChildren={true}
              headers={services.header}
              paginate={{
                activePage: filters.page,
                totalPages: listPatient.rowTotal,
                perPage: filters.perpage,
                pageRangeDisplayed: 1,
                onChangePage: async e => {
                  const result = await getListPatient({
                    url: '/medical/patient/listPatient/',
                    objFilters: { ...filters, page: e },
                    token: token,
                  });
                  setFilters({ ...filters, page: e });
                  formatData(result?.results);
                },
                showTextDetails: true,
              }}
            >
              {formatData(listPatient.results)}
            </OrdTable>
          </div>
        </OrdGenericTemplate>
      </div>
    </>
  );
};

export default PatientList;
